// search results
.DnnModule-SearchResults {
  .dnnSearchBoxPanel {
    max-width: 100%;
  }

  .dnnSearchBox_advanced {
    display: none;
  }

  .dnnSearchResultAdvancedTip {
    display: none;
  }

  .dnnSearchResultPanel {
    display: none;
  }

  .dnnSearchBox > a.dnnSearchBoxClearAdvanced {
    display: none;
  }

  .dnnSearchBox {
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    border-radius: 0;
    box-shadow: none;
  }

  .dnnSearchBoxPanel {
    background: transparent;
    padding: 0;
  }

  #dnnSearchResult_dnnSearchBox_input {
    position: relative;
    left: 0;
    height: auto;
    right: 0 !important;
    width: 100% !important;
    background: transparent;
  }

  .dnnSearchBox > a.dnnSearchBoxClearText {
    display: none;
  }

  .dnnSearchBox {
    position: relative;
    border: 1px solid $silver;
    border-radius: 6px;
  }

  #dnnSearchResult_dnnSearchBox_input {
    padding: 0 0 0 24px;
    width: calc(100% - 46px) !important;
    height: 46px;
    // @include lato-medium;
    @include fontsize(18 46);
    // color: $mineshaft;
  }

  a.dnnSearchButton {
    top: 0;
    right: 0;
    margin: 0;
    // padding: 14px;
    padding: 0;
    width: 46px;
    height: 46px;
    text-align: center;
    // background: none;
    background: url('../images/ICN-Search.svg') top center / 18px 54px no-repeat;
    border: 14px solid white;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    // &:before {
    // display: inline-block;
    // position: relative;
    // top: 14px;
    // font-style: normal;
    // font-variant: normal;
    // text-rendering: auto;
    // -webkit-font-smoothing: antialiased;
    // font-family: 'Font Awesome 5 Free';
    // font-weight: 900;
    // content: '\f002';
    // font-size: 30px;
    // color: $monza;
    // }

    &:hover,
    &:focus {
      background: url('../images/ICN-Search.svg') 0 -18px / 18px 54px no-repeat;
      // border: 0;

      &:before {
        // color: $mineshaft;
      }
    }
  }

  .dnnSearchResultContainer > .dnnSearchResultItem > .dnnSearchResultItem-Title {
    margin: 0;
    // @include lato-medium;
    @include fontsize(18 21);

    a {
      text-transform: none;
    }
  }

  .dnnSearchResultItem-Link a {
    position: relative;
    // top: -5px;
    // @include lato-light;
    text-transform: none;
    @include fontsize(15 18);
  }

  .dnnSearchResultItem-Others {
    display: none;
  }

  .dnnSearchResultItem-Subset {
    margin-bottom: 40px;
  }

  .dnnSearchResultPager.dnnSearchResultPagerTop {
    .dnnLeft {
      float: left;
      width: auto;
    }

    .dnnRight {
      float: right;
      width: auto;
    }

    .dnnClear {
      clear: both;
    }
  }
}
// .social {
// display: block;
// margin-top: 10px;
// padding: 0;
// @include medium-up {
//   display: flex;
//   margin-top: 0;
//   padding: 12px 0;
//   align-items: center;
//   justify-content: space-between;
// }

// &.bottom {
//   margin-top: 0;
// }

// .label {
//   position: relative;
//   bottom: -2px;
//   display: inline-block;
//   margin-right: rem-calc(10px);
//   text-transform: uppercase;
//   // letter-spacing: 0.063em;
//   font-weight: 500;
//   @include fontsize(14 14);
// }

ul.share-buttons:not(.dnnActions) {
  // display: inline-block;
  display: flex;
  justify-content: space-evenly;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: inline-block;
    // margin: 0 10px 0 0;
    margin: 0;
    padding: 0;
    width: calc(100% / 3 - 6px);

    // span {
    //   display: none;
    // }

    // &:last-child {
    //   margin-right: 0;
    // }
    &:before {
      display: none;
    }
  }

  [class*='btn-'] {
    display: block;
    position: relative;
    padding: 4px 5px 8px;
    @include fontsize(12 12 50);
    font-weight: 500;
    color: white;
    border-radius: 3px;

    span {
      // position: absolute;
      position: relative;
      // top: 0;
      top: 3px;
      // left: 5px;
      display: inline-block;
      margin-right: 6px;
      width: 16px;
      height: 16px;
      background-position: center center;
      background-position: center center;
      background-repeat: no-repeat;
      // content: '';
    }
  }

  [class*='facebook'] {
    background: #3b5998;

    &:visited,
    &:active {
      background: #3b5998;
    }

    &:hover,
    &:focus {
      background: darken(#3b5998, 10%);
    }

    span {
      background-image: url('../images/social/facebook.svg');
    }
  }

  [class*='twitter'] {
    background: #1da1f2;

    &:visited,
    &:active {
      background: #1da1f2;
    }

    &:hover,
    &:focus {
      background: darken(#1da1f2, 10%);
    }

    span {
      background-image: url('../images/social/twitter.svg');
    }
  }

  [class*='linkedin'] {
    background: #0077b5;

    &:visited,
    &:active {
      background: #0077b5;
    }

    &:hover,
    &:focus {
      background: darken(#0077b5, 10%);
    }

    span {
      background-image: url('../images/social/linkedin.svg');
    }
  }

  [class*='xing'] {
    background: #1a7576;

    &:visited,
    &:active {
      background: #1a7576;
    }

    &:hover,
    &:focus {
      background: darken(#1a7576, 10%);
    }

    span {
      background-image: url('../images/social/xing.svg');
    }
  }

  [class*='text'] {
    background: #0bbc28;

    &:visited,
    &:active {
      background: #0bbc28;
    }

    &:hover,
    &:focus {
      background: darken(#0bbc28, 10%);
    }

    span {
      background-image: url('../images/social/text.svg');
    }
  }

  [class*='mail'] {
    background: #dc4b3f;

    &:visited,
    &:active {
      background: #dc4b3f;
    }

    &:hover,
    &:focus {
      background: darken(#dc4b3f, 10%);
    }

    span {
      background-image: url('../images/social/mail.svg');
    }
  }
}
// }

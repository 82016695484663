table.responsive-table {
  table-layout: fixed;
  margin: 0 0 25px 0;
  width: 100%;
  // max-width: 0;
  text-align: left;
  @include fontsize(18 30);
  // border-collapse: collapse;
  // border-top-left-radius: 10px;
  // border-top-right-radius: 10px;
  border-radius: 10px;
  border-spacing: 0;
  overflow: hidden;
  @include large-up {
    // border: 1px solid $silver;
  }

  .cell-heading {
    display: none;
  }

  thead {
    background: $codgray;

    tr {
      display: none;
      @include franklin-demi;
      color: white;
      @include large-up {
        display: table-row;
      }
    }

    th {
      position: relative;
      @include large-up {
        padding: 12px 25px;
      }

      a {
        color: white;

        &:hover,
        &:focus {
          color: $silver;
        }
      }
    }
  }

  tbody {
    // width: calc(100% - 1px);
    // border: 1px solid $silver;
    @include large-up {
      border: 1px solid $silver;
    }

    tr {
      @include large-up {
        &:last-child td {
          border-bottom: 1px solid $silver;

          &:first-child {
            border-bottom-left-radius: 10px;
          }

          &:last-child {
            border-bottom-right-radius: 10px;
          }
        }

        td:last-child {
          border-right: 1px solid $silver;
        }

        td:first-child {
          border-left: 1px solid $silver;
        }

        &.ui-sortable-handle td:first-child {
          &:before {
            cursor: pointer;
            position: absolute;
            left: 20px;
            color: $silver;
            content: '?';

            &:focus {
              color: $monza;
            }
          }
        }
      }

      td {
        display: block;
        clear: left;
        word-wrap: break-word;
        vertical-align: top;
        @include large-up {
          display: table-cell;
          clear: none;
          padding: 12px 25px;
          border-bottom: 2px solid $mercury;

          // &:first-child {
          //   border-left: 1px solid $silver;
          // }
        }
      }
    }
  }
}

@include mq(0 $large) {
  table.responsive-table {
    width: 100%;

    thead {
      tr {
        display: none;
      }

      th {
        padding: 0;
      }
    }

    tbody tr {
      td {
        display: block;
        padding: 0;
        clear: left;
      }

      &:last-child td:last-child .cell-content {
        border-bottom: 2px solid $mercury;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    .cell-heading {
      display: block;
      padding: 5px 10px;
      @include franklin-demi;
      color: white;
      background: $codgray;
    }

    .cell-content {
      display: block;
      padding: 5px 10px;
      border-left: 2px solid $mercury;
      border-right: 2px solid $mercury;
    }
  }
}

.tablesorter-header {
  cursor: pointer;

  &:after {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    color: white;
    content: '?';
    opacity: 0.6;
  }

  &:hover:after,
  &:focus:after {
    opacity: 1;
  }
}
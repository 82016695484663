// tabs
.tabs {
  margin-bottom: 25px;

  &.scroll-left:before,
  &.scroll-right:after {
    height: 55px;
  }

  .tab-con {
    margin: 0;
  }

  .tab-list {
    li {
      margin-right: 5px;

      a {
        padding: 12px 30px;
        @include franklin-demi;
        @include fontsize(18 30);
        color: $codgray;
        text-transform: none;
        border-left: 1px solid $silver;
        border-top: 1px solid $silver;
        border-right: 1px solid $silver;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom: 0;
        background: white;

        &:hover,
        &:focus {
          color: white;
          background: $monza;
          border-color: $monza;
        }

        &[aria-selected] {
          padding-left: 30px;
          padding-right: 30px;
          color: white;
          background: $monza;
          border-color: $monza;
        }

        &[aria-selected]:hover,
        &[aria-selected]:focus {
          color: white;
          background: $monza;
          border-color: $monza;
        }
      }
    }
  }

  .tab-content {
    padding: 35px 30px;
    border: 1px solid $silver;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    @include large-up {
      border-top-right-radius: 10px;
    }

    *:last-of-type {
      margin-bottom: 0;
    }
  }
}
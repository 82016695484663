.LoginPanel {
  float: none;
  padding: 0;
  width: auto;
}

.dnnLoginService {
  display: flex;
  flex-wrap: wrap;
  // margin-left: -10px;
  // width: calc(100% + 20px);

  #dnn_ctr438_Login_Login_DNN_lblLogin,
  #dnn_ctr438_Login_Login_DNN_lblLoginRememberMe {
    display: none;
  }

  .dnnFormItem {
    padding: 0 10px;
    width: 50%;
    text-align: center;
    // float: left;
    + .dnnFormItem + .dnnFormItem {
      width: 100%;
      text-align: left;
    }

    .dnnLabel {
      text-align: left;
    }

    input[type='text'],
    input[type='password'] {
      margin-bottom: 20px;
      width: 100%;
      min-width: 0;
    }
  }

  .dnnLoginRememberMe {
    display: block;
    margin-top: 10px;
  }

  .dnnPrimaryAction,
  .dnnSecondaryAction {
    display: inline-block;
    padding: 10px 20px;
    @include franklin-cond-med;
    @include fontsize(18 24 100);
    text-transform: uppercase;
    text-decoration: none;
  }

  .dnnPrimaryAction {
    color: white;
    background: $monza;
    transition: color 0.2s ease-in-out, background 0.2s ease-in-out;

    &:visited,
    &:active {
      color: white;
      background: $monza;
    }

    &:hover,
    &:focus {
      color: white;
      background: $codgray;
    }
  }

  .dnnSecondaryAction {
    color: white;
    background: $codgray;
    transition: color 0.2s ease-in-out, background 0.2s ease-in-out;

    &:visited,
    &:active {
      color: white;
      background: $codgray;
    }

    &:hover,
    &:focus {
      color: white;
      background: $monza;
    }
  }

  .dnnLoginActions .dnnActions {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      // margin-right: 10px;
      margin-bottom: 10px;
      @include medium-up {
        margin-right: 10px;
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
        @include medium-up {
          margin-right: 0;
        }
      }
    }
  }
}
/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: black;
  background: white;
}

.offscreen {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.hide {
  display: none;
}

.show {
  display: initial;
}

.invisible {
  visibility: hidden;
}

.clear {
  display: block;
  clear: both;
}

.clear-after:after {
  display: table;
  clear: both;
  content: "";
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media (orientation: landscape) {
  .show-landscape {
    display: block !important;
  }
}
@media (orientation: portrait) {
  .show-landscape {
    display: none !important;
  }
}

@media (orientation: portrait) {
  .show-portrait {
    display: block !important;
  }
}
@media (orientation: landscape) {
  .show-portrait {
    display: none !important;
  }
}

@media (orientation: landscape) {
  .hide-landscape {
    display: none !important;
  }
}
@media (orientation: portrait) {
  .hide-landscape {
    display: block !important;
  }
}

@media (orientation: portrait) {
  .hide-portrait {
    display: none !important;
  }
}
@media (orientation: landscape) {
  .hide-portrait {
    display: block !important;
  }
}

@media (min-width: 640px) {
  .show-small-only {
    display: none !important;
  }
}

@media (min-width: 0) and (max-width: 640px) {
  .show-medium-only {
    display: none !important;
  }
}
@media (min-width: 1024px) {
  .show-medium-only {
    display: none !important;
  }
}

.show-large-only {
  display: none !important;
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .show-large-only {
    display: block !important;
  }
}
@media (min-width: 1280px) {
  .show-large-only {
    display: none !important;
  }
}

.show-xlarge-only {
  display: none !important;
}
@media (min-width: 1280px) and (max-width: 1599px) {
  .show-xlarge-only {
    display: block !important;
  }
}
@media (min-width: 1600px) {
  .show-xlarge-only {
    display: none !important;
  }
}

.show-xxlarge-only {
  display: none !important;
}
@media (min-width: 1600px) {
  .show-xxlarge-only {
    display: none !important;
  }
}

.show-medium-up {
  display: none !important;
}
@media (min-width: 640px) {
  .show-medium-up {
    display: block !important;
  }
}

.show-large-up {
  display: none !important;
}
@media (min-width: 1024px) {
  .show-large-up {
    display: block !important;
  }
}

.show-xlarge-up {
  display: none !important;
}
@media (min-width: 1280px) {
  .show-xlarge-up {
    display: block !important;
  }
}

.show-xxlarge-up {
  display: none !important;
}
@media (min-width: 1600px) {
  .show-xxlarge-up {
    display: block !important;
  }
}

@media (min-width: 0) and (max-width: 640px) {
  .hide-small-only {
    display: none !important;
  }
}

@media (min-width: 640px) and (max-width: 1023px) {
  .hide-medium-only {
    display: none !important;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .hide-large-only {
    display: none !important;
  }
}

@media (min-width: 1280px) and (max-width: 1599px) {
  .hide-xlarge-only {
    display: none !important;
  }
}

@media (min-width: 1600px) {
  .hide-xxlarge-only {
    display: none !important;
  }
}

@media (min-width: 640px) {
  .hide-medium-up {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .hide-large-up {
    display: none !important;
  }
}

@media (min-width: 1280px) {
  .hide-xlarge-up {
    display: none !important;
  }
}

@media (min-width: 1600px) {
  .hide-xxlarge-up {
    display: none !important;
  }
}

.accordions.active {
  margin-bottom: 20px;
}
.accordions.active h2.accordion-title {
  position: relative;
  margin: 0;
  padding: 0;
}
.accordions.active h2.accordion-title button {
  position: relative;
  width: 100%;
  margin: 0 0 10px 0;
  padding: 20px 82px 10px 0;
  text-align: left;
  color: black;
  border: 0;
  border-bottom: 2px solid gray;
  background: transparent;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  letter-spacing: normal;
}
.accordions.active h2.accordion-title button span {
  position: absolute;
  right: 0;
  bottom: -2px;
  width: 80px;
  height: calc(100% + 2px);
  border-bottom: 2px solid gray;
}
.accordions.active h2.accordion-title button span:after {
  position: absolute;
  top: 50%;
  left: 32px;
  content: "\f067";
  transform: translateY(-8px);
  text-align: center;
  text-decoration: inherit;
  color: black;
  font-family: FontAwesome;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
}
.accordions.active h2.accordion-title button:hover {
  cursor: pointer;
}
.accordions.active h2.accordion-title button:hover, .accordions.active h2.accordion-title button:focus {
  color: blue;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  background: transparent;
}
.accordions.active h2.accordion-title button:hover span, .accordions.active h2.accordion-title button:focus span {
  border-bottom: 2px solid gray;
}
.accordions.active h2.accordion-title.is-active button {
  color: blue;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  background: transparent;
}
.accordions.active h2.accordion-title.is-active button span {
  border-bottom: 2px solid gray;
}
.accordions.active h2.accordion-title.is-active button span:after {
  content: "\f068";
}
.accordions.active h2.accordion-title.is-active button:focus {
  color: blue;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  background: transparent;
}
.accordions.active h2.accordion-title.is-active button:focus span {
  border-bottom: 2px solid gray;
}
.accordions.active h2.accordion-title.is-active button:focus span:after {
  content: "\f068";
}
.accordions.active .accordion-content[aria-hidden=true] {
  display: none;
}
.accordions.active .accordion-content[aria-hidden=false] {
  display: block;
}
.accordions.active .accordion-content {
  padding: 10px;
}

.two-column,
.three-column,
.four-column,
.five-column,
.six-column {
  list-style: none !important;
  margin: 0;
  padding: 0;
}
@media (min-width: 640px) {
  .two-column,
  .three-column,
  .four-column,
  .five-column,
  .six-column {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
  }
}
.two-column .col,
.three-column .col,
.four-column .col,
.five-column .col,
.six-column .col {
  width: 100%;
}

@media (min-width: 640px) {
  .two-column .col {
    margin: 0 15px 15px 15px;
    width: calc(50% - 30px / 2);
  }
  .two-column .col:nth-of-type(2n+1) {
    margin-left: 0;
  }
  .two-column .col:nth-of-type(2n) {
    margin-right: 0;
  }
}

@media (min-width: 640px) {
  .three-column .col {
    margin: 0 15px 15px 15px;
    width: calc((100% - 30px) / 2);
  }
  .three-column .col:nth-of-type(2n+1) {
    margin-left: 0;
  }
  .three-column .col:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media (min-width: 1024px) {
  .three-column .col {
    margin: 0 15px 15px 15px;
    width: calc((100% - 30px * 2) / 3);
  }
  .three-column .col:nth-of-type(2n+1) {
    margin-left: 15px;
  }
  .three-column .col:nth-of-type(2n) {
    margin-right: 15px;
  }
  .three-column .col:nth-of-type(3n+1) {
    margin-left: 0;
  }
  .three-column .col:nth-of-type(3n) {
    margin-right: 0;
  }
}

@media (min-width: 640px) {
  .four-column .col {
    margin: 0 15px 15px 15px;
    width: calc((100% - 30px) / 2);
  }
  .four-column .col:nth-of-type(2n+1) {
    margin-left: 0;
  }
  .four-column .col:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media (min-width: 1024px) {
  .four-column .col {
    margin: 0 15px 15px 15px;
    width: calc((100% - 30px * 3) / 4);
  }
  .four-column .col:nth-of-type(2n+1) {
    margin-left: 15px;
  }
  .four-column .col:nth-of-type(2n) {
    margin-right: 15px;
  }
  .four-column .col:nth-of-type(4n+1) {
    margin-left: 0;
  }
  .four-column .col:nth-of-type(4n) {
    margin-right: 0;
  }
}

@media (min-width: 640px) {
  .five-column .col {
    margin: 0 15px 15px 15px;
    width: calc((100% - 30px) / 2);
  }
  .five-column .col:nth-of-type(2n+1) {
    margin-left: 0;
  }
  .five-column .col:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media (min-width: 1024px) {
  .five-column .col {
    margin: 0 15px 15px 15px;
    width: calc((100% - 30px * 4) / 5);
  }
  .five-column .col:nth-of-type(2n+1) {
    margin-left: 15px;
  }
  .five-column .col:nth-of-type(2n) {
    margin-right: 15px;
  }
  .five-column .col:nth-of-type(5n+1) {
    margin-left: 0;
  }
  .five-column .col:nth-of-type(5n) {
    margin-right: 0;
  }
}

@media (min-width: 640px) {
  .six-column .col {
    margin: 0 15px 15px 15px;
    width: calc((100% - 30px) / 2);
  }
  .six-column .col:nth-of-type(2n+1) {
    margin-left: 0;
  }
  .six-column .col:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media (min-width: 1024px) {
  .six-column .col {
    margin: 0 15px 15px 15px;
    width: calc((100% - 30px * 5) / 6);
  }
  .six-column .col:nth-of-type(2n+1) {
    margin-left: 15px;
  }
  .six-column .col:nth-of-type(2n) {
    margin-right: 15px;
  }
  .six-column .col:nth-of-type(6n+1) {
    margin-left: 0;
  }
  .six-column .col:nth-of-type(6n) {
    margin-right: 0;
  }
}

body.modal-open {
  overflow: hidden;
}

.modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s;
  z-index: 1071;
}
.modal-overlay.show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s;
}

.modal {
  display: none;
  background-color: #fff;
  text-align: center;
  width: 98vw;
  height: auto;
  min-height: calc(90vh - 40px);
  max-height: calc(90vh - 40px);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  overflow: visible;
  z-index: 1072;
}
@media only screen and (min-width: 640px) {
  .modal {
    width: 96vw;
    min-height: auto;
    height: auto;
    max-height: 90vh;
    overflow-y: visible;
  }
}
@media only screen and (min-width: 1024px) {
  .modal {
    width: 60vw;
    max-width: 500px;
    min-height: auto;
    height: auto;
    max-height: 90vh;
    overflow: visible;
  }
  .modal.wide {
    width: 80vw;
    max-width: 80vw;
  }
}
@media only screen and (min-width: 1280px) {
  .modal {
    width: 50vw;
    max-width: 640px;
    min-height: auto;
    height: auto;
    max-height: 90vh;
    overflow: visible;
  }
  .modal.wide {
    width: 80vw;
    max-width: 80vw;
  }
}
.modal.show-modal {
  display: block;
  opacity: 1;
  visibility: visible;
  transition: visibility 0s linear 0s, opacity 0.25s 0s;
}
.modal .close-modal {
  position: absolute;
  top: 0.5rem;
  right: 1.5rem;
  width: 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: lightgray;
}
.modal .close-modal:hover {
  background-color: darkgray;
}
.modal .modal-content {
  padding: 31px 20px 20px;
  height: 100%;
  overflow-y: auto;
  max-height: inherit;
}

.modal-inline-parent {
  position: relative !important;
  z-index: auto !important;
}

table.responsive-table .cell-heading {
  display: none;
}

@media screen and (max-width: 1023px) {
  table.responsive-table {
    width: 100%;
  }
  table.responsive-table thead tr {
    display: none;
  }
  table.responsive-table td {
    display: block;
    clear: left;
  }
  table.responsive-table .cell-heading {
    display: block;
  }
  table.responsive-table .cell-content {
    display: block;
  }
}
.tabs {
  position: relative;
  margin-bottom: 20px;
}
.tabs.scroll-left:before, .tabs.scroll-right:after {
  position: absolute;
  z-index: 99;
  width: 40px;
  height: 60px;
  content: "";
}
.tabs.scroll-left:before {
  top: 0;
  left: 0;
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
}
.tabs.scroll-right:after {
  top: 0;
  right: 0;
  background: linear-gradient(to left, white 0%, rgba(255, 255, 255, 0) 100%);
}
.tabs .tab-con {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 2px;
  padding: 0;
  white-space: nowrap;
}
.tabs .tab-list {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  letter-spacing: normal;
}
.tabs .tab-list[role=tablist] {
  display: inline-block;
}
.tabs .tab-list li {
  display: inline-block;
  margin: 0 3px 0 0;
  padding: 0;
  white-space: nowrap;
}
.tabs .tab-list li:last-child {
  margin: 0;
}
.tabs .tab-list li a {
  position: relative;
  display: block;
  padding: 12px 19px;
  background: gray;
  color: black;
  text-align: center;
  text-decoration: none;
}
.tabs .tab-list li a:hover, .tabs .tab-list li a:focus {
  outline: 0;
  background: black;
  color: white;
}
.tabs .tab-list li a[aria-selected] {
  border: 0;
  background: gray;
  color: black;
  padding: 12px 19px;
}
.tabs .tab-list li a[aria-selected]:hover, .tabs .tab-list li a[aria-selected]:focus {
  background: gray;
  color: black;
}
.tabs [role=tabpanel][aria-hidden=true] {
  display: none;
}
.tabs [role=tabpanel] .tab-title {
  display: none;
}

.flex-container {
  margin: 0 auto 10px auto;
  width: 100%;
  max-width: 100%;
}
@media (min-width: 1024px) {
  .flex-container {
    margin-bottom: 20px;
  }
  .flex-container.alignleft {
    width: 40%;
    float: left;
    margin: 0 15px 10px 0;
  }
  .flex-container.alignright {
    width: 40%;
    float: right;
    margin: 0 0 10px 15px;
  }
  .flex-container.aligncenter {
    width: 40%;
    margin: 0 auto 10px auto;
  }
}

.flex-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}
.flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.sidebar {
  padding-top: 20px;
  width: 100%;
}
@media (min-width: 1280px) {
  .sidebar {
    padding-top: 46px;
    padding-right: 80px;
    width: 32%;
  }
}

.callout {
  position: relative;
  margin: 0 0 20px 0;
  padding: 0 20px 75px 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
  overflow: hidden;
}
@media (min-width: 640px) {
  .callout {
    margin: 0 0 40px 0;
    padding: 0 40px 25px 40px;
  }
}
.callout:last-of-type {
  margin-bottom: 0;
}
.callout > p {
  padding: 0;
}
.callout img {
  position: relative;
  left: -20px;
  width: calc(100% + 40px);
  max-width: calc(100% + 40px);
}
@media (min-width: 640px) {
  .callout img {
    left: -40px;
    width: calc(100% + 80px);
    max-width: calc(100% + 80px);
  }
}
.callout .btn-primary {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 12px 0;
  width: 100%;
  height: 50px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  line-height: 25px;
  font-size: 1rem;
  line-height: 1.5625rem;
  letter-spacing: 0.05em;
  color: white;
  text-decoration: none;
  text-transform: none;
  text-align: center;
  background: #d50032;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: background 0.2s ease-in-out;
}
.callout .btn-primary:visited {
  color: white;
}
.callout .btn-primary:hover, .callout .btn-primary:focus {
  color: white;
  text-decoration: none;
  background: #404040;
  transition: background 0.2s ease-in-out;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}
@media (min-width: 640px) {
  img {
    max-width: 100%;
  }
}
img[class*=left] {
  float: left;
  max-width: 40%;
  margin: 0 20px 15px 0;
}
@media (min-width: 640px) {
  img[class*=left] {
    margin: 0 50px 20px 0;
  }
}
img[class*=right] {
  float: right;
  max-width: 40%;
  margin: 0 0 15px 20px;
}
@media (min-width: 640px) {
  img[class*=right] {
    margin: 0 0 20px 50px;
  }
}
img[class*=center] {
  margin: 0 auto 15px auto;
}
@media (min-width: 640px) {
  img[class*=center] {
    margin: 0 auto 20px auto;
  }
}

figure {
  display: block;
  margin: 0 0 25px 0;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}
@media (min-width: 640px) {
  figure {
    max-width: 100%;
  }
}
figure img {
  display: block;
  margin: 0 auto;
}
figure img[class*=align] {
  margin: 0 auto;
  float: none;
}
@media (min-width: 640px) {
  figure img[class*=align] {
    margin: 0 auto;
    float: none;
  }
}
figure[class*=left] {
  float: left;
  max-width: 40%;
  margin: 0 20px 15px 0;
}
@media (min-width: 640px) {
  figure[class*=left] {
    margin: 0 50px 20px 0;
  }
}
figure[class*=right] {
  float: right;
  max-width: 40%;
  margin: 0 0 15px 20px;
}
@media (min-width: 640px) {
  figure[class*=right] {
    margin: 0 0 20px 50px;
  }
}
figure[class*=center] {
  margin: 0 auto 15px auto;
}
@media (min-width: 640px) {
  figure[class*=center] {
    margin: 0 auto 20px auto;
  }
}
figure figcaption {
  margin: 0 auto;
  font-style: italic;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: normal;
  background: transparent;
  padding: 10px 0 0;
}

table.responsive-table {
  table-layout: fixed;
  margin: 0 0 25px 0;
  width: 100%;
  text-align: left;
  font-size: 18px;
  line-height: 30px;
  font-size: 1.125rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  border-radius: 10px;
  border-spacing: 0;
  overflow: hidden;
}
table.responsive-table .cell-heading {
  display: none;
}
table.responsive-table thead {
  background: #1a1a1a;
}
table.responsive-table thead tr {
  display: none;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: white;
}
@media (min-width: 1024px) {
  table.responsive-table thead tr {
    display: table-row;
  }
}
table.responsive-table thead th {
  position: relative;
}
@media (min-width: 1024px) {
  table.responsive-table thead th {
    padding: 12px 25px;
  }
}
table.responsive-table thead th a {
  color: white;
}
table.responsive-table thead th a:hover, table.responsive-table thead th a:focus {
  color: #cccccc;
}
@media (min-width: 1024px) {
  table.responsive-table tbody {
    border: 1px solid #cccccc;
  }
}
@media (min-width: 1024px) {
  table.responsive-table tbody tr:last-child td {
    border-bottom: 1px solid #cccccc;
  }
  table.responsive-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }
  table.responsive-table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
  table.responsive-table tbody tr td:last-child {
    border-right: 1px solid #cccccc;
  }
  table.responsive-table tbody tr td:first-child {
    border-left: 1px solid #cccccc;
  }
  table.responsive-table tbody tr.ui-sortable-handle td:first-child:before {
    cursor: pointer;
    position: absolute;
    left: 20px;
    color: #cccccc;
    content: "?";
  }
  table.responsive-table tbody tr.ui-sortable-handle td:first-child:before:focus {
    color: #d50032;
  }
}
table.responsive-table tbody tr td {
  display: block;
  clear: left;
  word-wrap: break-word;
  vertical-align: top;
}
@media (min-width: 1024px) {
  table.responsive-table tbody tr td {
    display: table-cell;
    clear: none;
    padding: 12px 25px;
    border-bottom: 2px solid #e6e6e6;
  }
}

@media screen and (max-width: 1023px) {
  table.responsive-table {
    width: 100%;
  }
  table.responsive-table thead tr {
    display: none;
  }
  table.responsive-table thead th {
    padding: 0;
  }
  table.responsive-table tbody tr td {
    display: block;
    padding: 0;
    clear: left;
  }
  table.responsive-table tbody tr:last-child td:last-child .cell-content {
    border-bottom: 2px solid #e6e6e6;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  table.responsive-table .cell-heading {
    display: block;
    padding: 5px 10px;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: white;
    background: #1a1a1a;
  }
  table.responsive-table .cell-content {
    display: block;
    padding: 5px 10px;
    border-left: 2px solid #e6e6e6;
    border-right: 2px solid #e6e6e6;
  }
}
.tablesorter-header {
  cursor: pointer;
}
.tablesorter-header:after {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  color: white;
  content: "?";
  opacity: 0.6;
}
.tablesorter-header:hover:after, .tablesorter-header:focus:after {
  opacity: 1;
}

.tabs {
  margin-bottom: 25px;
}
.tabs.scroll-left:before, .tabs.scroll-right:after {
  height: 55px;
}
.tabs .tab-con {
  margin: 0;
}
.tabs .tab-list li {
  margin-right: 5px;
}
.tabs .tab-list li a {
  padding: 12px 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
  font-size: 1.125rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  color: #1a1a1a;
  text-transform: none;
  border-left: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 0;
  background: white;
}
.tabs .tab-list li a:hover, .tabs .tab-list li a:focus {
  color: white;
  background: #d50032;
  border-color: #d50032;
}
.tabs .tab-list li a[aria-selected] {
  padding-left: 30px;
  padding-right: 30px;
  color: white;
  background: #d50032;
  border-color: #d50032;
}
.tabs .tab-list li a[aria-selected]:hover, .tabs .tab-list li a[aria-selected]:focus {
  color: white;
  background: #d50032;
  border-color: #d50032;
}
.tabs .tab-content {
  padding: 35px 30px;
  border: 1px solid #cccccc;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
@media (min-width: 1024px) {
  .tabs .tab-content {
    border-top-right-radius: 10px;
  }
}
.tabs .tab-content *:last-of-type {
  margin-bottom: 0;
}

.accordions.active {
  margin: 0 0 25px 0;
}
.accordions.active h2.accordion-title button {
  padding: 12px 80px 12px 0;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
  font-size: 1.125rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  color: #1a1a1a;
  border: 0;
}
.accordions.active h2.accordion-title button:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 80px);
  height: 2px;
  background: rgba(26, 26, 26, 0.3);
  content: "";
}
.accordions.active h2.accordion-title button:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 75px;
  height: 100%;
  background: url("../images/ICN-Plus.svg") center center/15px 15px no-repeat;
  border-bottom: 2px solid #1a1a1a;
  content: "";
}
.accordions.active h2.accordion-title button:hover,
.accordions.active h2.accordion-title button:focus {
  color: #d50032;
  border: 0;
}
.accordions.active h2.accordion-title button:hover:before,
.accordions.active h2.accordion-title button:focus:before {
  background: #d50032;
}
.accordions.active h2.accordion-title button:hover:after,
.accordions.active h2.accordion-title button:focus:after {
  background: url("../images/ICN-Plus-Red.svg") center center/15px 15px no-repeat;
  border-color: #d50032;
}
.accordions.active h2.accordion-title.is-active button {
  color: #1a1a1a;
  border: 0;
}
.accordions.active h2.accordion-title.is-active button:hover, .accordions.active h2.accordion-title.is-active button:focus {
  color: #1a1a1a;
  border: 0;
}
.accordions.active h2.accordion-title.is-active button:before {
  background: #1a1a1a;
}
.accordions.active h2.accordion-title.is-active button:after {
  background: url("../images/ICN-Minus-Black.svg") center center/15px 3px no-repeat;
  border-color: #1a1a1a;
}
.accordions.active .accordion-content {
  padding: 0;
}

.subpage .banner-wrapper {
  position: relative;
  height: 120px;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("../images/bg-banner-sp.jpg");
  background-color: #404040;
}
@media (min-width: 640px) {
  .subpage .banner-wrapper {
    background-image: url("../images/bg-banner-sp@2x.jpg");
  }
}
@media (min-width: 1280px) {
  .subpage .banner-wrapper {
    height: 200px;
    flex: 0 0 100%;
  }
}
.subpage .banner {
  display: none;
}
@media (min-width: 1280px) {
  .subpage .banner {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 32%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 3;
    border-left: 1px solid white;
  }
}

.cta {
  display: flex;
  position: relative;
  align-items: center;
  margin: 50px 0;
  padding: 50px 65px 50px 0;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  line-height: 30px;
  font-size: 1.5rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  color: #1a1a1a;
  text-decoration: none;
  border-top: 2px solid #cccccc;
  border-bottom: 2px solid #cccccc;
}
.cta:hover {
  color: #1a1a1a;
}
.cta:hover:after {
  right: 0;
  transition: right 0.2s ease-in-out;
}
.cta:after {
  display: block;
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  width: 25px;
  height: 23px;
  background: url("../images/ICN-Arrow-Red.svg") center center/25px 23px no-repeat;
  content: "";
  transition: right 0.2s ease-in-out;
}
.cta .icon {
  width: 80px;
}
.cta .icon p {
  margin: 0;
  padding: 0;
  line-height: 1;
}
.cta .icon img {
  margin: 0;
  padding: 0;
}
.cta .img {
  width: 140px;
}
.cta .img p {
  margin: 0;
  padding: 0;
  line-height: 1;
}
.cta .img img {
  margin: 0;
  padding: 0;
}
.cta .content {
  padding: 0 0 0 20px;
}
.cta.cta-icon .content {
  width: calc(100% - 80px);
}
.cta p {
  font-size: 24px;
  line-height: 30px;
  font-size: 1.5rem;
  line-height: 1.875rem;
  letter-spacing: normal;
}
.cta p:last-of-type {
  margin-bottom: 0;
}

.LoginPanel {
  float: none;
  padding: 0;
  width: auto;
}

.dnnLoginService {
  display: flex;
  flex-wrap: wrap;
}
.dnnLoginService #dnn_ctr438_Login_Login_DNN_lblLogin,
.dnnLoginService #dnn_ctr438_Login_Login_DNN_lblLoginRememberMe {
  display: none;
}
.dnnLoginService .dnnFormItem {
  padding: 0 10px;
  width: 50%;
  text-align: center;
}
.dnnLoginService .dnnFormItem + .dnnFormItem + .dnnFormItem {
  width: 100%;
  text-align: left;
}
.dnnLoginService .dnnFormItem .dnnLabel {
  text-align: left;
}
.dnnLoginService .dnnFormItem input[type=text],
.dnnLoginService .dnnFormItem input[type=password] {
  margin-bottom: 20px;
  width: 100%;
  min-width: 0;
}
.dnnLoginService .dnnLoginRememberMe {
  display: block;
  margin-top: 10px;
}
.dnnLoginService .dnnPrimaryAction,
.dnnLoginService .dnnSecondaryAction {
  display: inline-block;
  padding: 10px 20px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
}
.dnnLoginService .dnnPrimaryAction {
  color: white;
  background: #d50032;
  transition: color 0.2s ease-in-out, background 0.2s ease-in-out;
}
.dnnLoginService .dnnPrimaryAction:visited, .dnnLoginService .dnnPrimaryAction:active {
  color: white;
  background: #d50032;
}
.dnnLoginService .dnnPrimaryAction:hover, .dnnLoginService .dnnPrimaryAction:focus {
  color: white;
  background: #1a1a1a;
}
.dnnLoginService .dnnSecondaryAction {
  color: white;
  background: #1a1a1a;
  transition: color 0.2s ease-in-out, background 0.2s ease-in-out;
}
.dnnLoginService .dnnSecondaryAction:visited, .dnnLoginService .dnnSecondaryAction:active {
  color: white;
  background: #1a1a1a;
}
.dnnLoginService .dnnSecondaryAction:hover, .dnnLoginService .dnnSecondaryAction:focus {
  color: white;
  background: #d50032;
}
.dnnLoginService .dnnLoginActions .dnnActions {
  list-style: none;
  margin: 0;
  padding: 0;
}
.dnnLoginService .dnnLoginActions .dnnActions li {
  display: inline-block;
  margin-bottom: 10px;
}
@media (min-width: 640px) {
  .dnnLoginService .dnnLoginActions .dnnActions li {
    margin-right: 10px;
    margin-bottom: 0;
  }
}
.dnnLoginService .dnnLoginActions .dnnActions li:last-child {
  margin-bottom: 0;
}
@media (min-width: 640px) {
  .dnnLoginService .dnnLoginActions .dnnActions li:last-child {
    margin-right: 0;
  }
}

.DnnModule-SearchResults .dnnSearchBoxPanel {
  max-width: 100%;
}
.DnnModule-SearchResults .dnnSearchBox_advanced {
  display: none;
}
.DnnModule-SearchResults .dnnSearchResultAdvancedTip {
  display: none;
}
.DnnModule-SearchResults .dnnSearchResultPanel {
  display: none;
}
.DnnModule-SearchResults .dnnSearchBox > a.dnnSearchBoxClearAdvanced {
  display: none;
}
.DnnModule-SearchResults .dnnSearchBox {
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  border-radius: 0;
  box-shadow: none;
}
.DnnModule-SearchResults .dnnSearchBoxPanel {
  background: transparent;
  padding: 0;
}
.DnnModule-SearchResults #dnnSearchResult_dnnSearchBox_input {
  position: relative;
  left: 0;
  height: auto;
  right: 0 !important;
  width: 100% !important;
  background: transparent;
}
.DnnModule-SearchResults .dnnSearchBox > a.dnnSearchBoxClearText {
  display: none;
}
.DnnModule-SearchResults .dnnSearchBox {
  position: relative;
  border: 1px solid #cccccc;
  border-radius: 6px;
}
.DnnModule-SearchResults #dnnSearchResult_dnnSearchBox_input {
  padding: 0 0 0 24px;
  width: calc(100% - 46px) !important;
  height: 46px;
  font-size: 18px;
  line-height: 46px;
  font-size: 1.125rem;
  line-height: 2.875rem;
  letter-spacing: normal;
}
.DnnModule-SearchResults a.dnnSearchButton {
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  width: 46px;
  height: 46px;
  text-align: center;
  background: url("../images/ICN-Search.svg") top center/18px 54px no-repeat;
  border: 14px solid white;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.DnnModule-SearchResults a.dnnSearchButton:hover, .DnnModule-SearchResults a.dnnSearchButton:focus {
  background: url("../images/ICN-Search.svg") 0 -18px/18px 54px no-repeat;
}
.DnnModule-SearchResults .dnnSearchResultContainer > .dnnSearchResultItem > .dnnSearchResultItem-Title {
  margin: 0;
  font-size: 18px;
  line-height: 21px;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  letter-spacing: normal;
}
.DnnModule-SearchResults .dnnSearchResultContainer > .dnnSearchResultItem > .dnnSearchResultItem-Title a {
  text-transform: none;
}
.DnnModule-SearchResults .dnnSearchResultItem-Link a {
  position: relative;
  text-transform: none;
  font-size: 15px;
  line-height: 18px;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  letter-spacing: normal;
}
.DnnModule-SearchResults .dnnSearchResultItem-Others {
  display: none;
}
.DnnModule-SearchResults .dnnSearchResultItem-Subset {
  margin-bottom: 40px;
}
.DnnModule-SearchResults .dnnSearchResultPager.dnnSearchResultPagerTop .dnnLeft {
  float: left;
  width: auto;
}
.DnnModule-SearchResults .dnnSearchResultPager.dnnSearchResultPagerTop .dnnRight {
  float: right;
  width: auto;
}
.DnnModule-SearchResults .dnnSearchResultPager.dnnSearchResultPagerTop .dnnClear {
  clear: both;
}

.content-form.overflow,
.form-container.overflow,
.dnnForm.overflow {
  overflow: visible;
}
.content-form label,
.content-form .label,
.form-container label,
.form-container .label,
.dnnForm label,
.dnnForm .label {
  display: block;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
  font-size: 1.125rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  text-align: left;
  color: #1a1a1a;
}
.content-form input[type=text],
.content-form input[type=password],
.content-form input[type=date],
.content-form input[type=datetime],
.content-form input[type=datetime-local],
.content-form input[type=month],
.content-form input[type=week],
.content-form input[type=email],
.content-form input[type=number],
.content-form input[type=search],
.content-form input[type=tel],
.content-form input[type=time],
.content-form input[type=url],
.content-form input[type=file],
.content-form textarea,
.content-form select,
.form-container input[type=text],
.form-container input[type=password],
.form-container input[type=date],
.form-container input[type=datetime],
.form-container input[type=datetime-local],
.form-container input[type=month],
.form-container input[type=week],
.form-container input[type=email],
.form-container input[type=number],
.form-container input[type=search],
.form-container input[type=tel],
.form-container input[type=time],
.form-container input[type=url],
.form-container input[type=file],
.form-container textarea,
.form-container select,
.dnnForm input[type=text],
.dnnForm input[type=password],
.dnnForm input[type=date],
.dnnForm input[type=datetime],
.dnnForm input[type=datetime-local],
.dnnForm input[type=month],
.dnnForm input[type=week],
.dnnForm input[type=email],
.dnnForm input[type=number],
.dnnForm input[type=search],
.dnnForm input[type=tel],
.dnnForm input[type=time],
.dnnForm input[type=url],
.dnnForm input[type=file],
.dnnForm textarea,
.dnnForm select {
  padding: 0 10px;
  height: 55px;
  width: 100%;
  font-size: 18px;
  line-height: 55px;
  font-size: 1.125rem;
  line-height: 3.4375rem;
  letter-spacing: normal;
  border: 1px solid #cccccc;
  border-radius: 10px;
}
.content-form select,
.form-container select,
.dnnForm select {
  background: url("../images/ICN-caret.svg") top right/55px 110px no-repeat;
}
.content-form select.open,
.form-container select.open,
.dnnForm select.open {
  background-position: bottom right;
}
.content-form textarea,
.form-container textarea,
.dnnForm textarea {
  min-height: 110px;
  font-size: 18px;
  line-height: 24px;
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}
.content-form .dnnLoginActions .dnnActions li,
.form-container .dnnLoginActions .dnnActions li,
.dnnForm .dnnLoginActions .dnnActions li {
  padding: 0;
}
.content-form .dnnLoginActions .dnnActions li:before,
.form-container .dnnLoginActions .dnnActions li:before,
.dnnForm .dnnLoginActions .dnnActions li:before {
  display: none;
}
.content-form input[type=checkbox],
.content-form input[type=radio],
.form-container input[type=checkbox],
.form-container input[type=radio],
.dnnForm input[type=checkbox],
.dnnForm input[type=radio] {
  border: 1px solid #cccccc;
}
.content-form input[type=checkbox] + label,
.content-form input[type=radio] + label,
.form-container input[type=checkbox] + label,
.form-container input[type=radio] + label,
.dnnForm input[type=checkbox] + label,
.dnnForm input[type=radio] + label {
  display: inline-block;
  top: -5px;
}
.content-form input[type=checkbox]:checked,
.content-form input[type=radio]:checked,
.form-container input[type=checkbox]:checked,
.form-container input[type=radio]:checked,
.dnnForm input[type=checkbox]:checked,
.dnnForm input[type=radio]:checked {
  border-color: #d50032;
  background: #d50032;
  appearance: none;
}
.content-form input[type=checkbox]:checked:before,
.content-form input[type=radio]:checked:before,
.form-container input[type=checkbox]:checked:before,
.form-container input[type=radio]:checked:before,
.dnnForm input[type=checkbox]:checked:before,
.dnnForm input[type=radio]:checked:before {
  position: absolute;
  top: 5px;
  left: 3px;
  width: 12px;
  height: 9px;
  background: url("../images/ICN-Checkmark.svg") center center/contain no-repeat;
  content: "";
}
.content-form input[type=radio]:checked:before,
.form-container input[type=radio]:checked:before,
.dnnForm input[type=radio]:checked:before {
  display: none;
}

.store .page-title {
  top: -160px;
}
@media (min-width: 1280px) {
  .store .page-title {
    top: -256px;
  }
}
.store .store-nav {
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}
.store .store-nav a {
  display: inline-block;
  position: relative;
  padding: 12px 0;
  width: 50%;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
  font-size: 1.125rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  color: #1a1a1a;
  text-transform: none;
  text-align: center;
  border-bottom: 0;
  background: #cccccc;
}
.store .store-nav a:after {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 12px 0 12px;
  border-color: #cccccc transparent transparent transparent;
  content: "";
  z-index: 100;
}
.store .store-nav a span {
  display: inline-block;
  position: relative;
  bottom: -4px;
  margin-right: 5px;
  transition: background-position ease-in-out 0.2s;
}
.store .store-nav a:first-child span {
  width: 18px;
  height: 22.5px;
  background: url("../images/ICN-Locations-Pin.svg") center bottom/18px 45px no-repeat;
}
.store .store-nav a:last-child span {
  width: 22px;
  height: 25px;
  background: url("../images/ICN-Route-Pin.svg") center bottom/22px 50px no-repeat;
}
.store .store-nav a:hover, .store .store-nav a:focus {
  color: white;
  background: #d50032;
}
.store .store-nav a:hover:first-child span, .store .store-nav a:focus:first-child span {
  background-position: center top;
}
.store .store-nav a:hover:last-child span, .store .store-nav a:focus:last-child span {
  background-position: center top;
}
.store .store-nav a:hover:after, .store .store-nav a:focus:after {
  border-color: #d50032 transparent transparent transparent;
}
.store .store-nav a:active, .store .store-nav a.active {
  color: white;
  background: #d50032;
}
.store .store-nav a:active span, .store .store-nav a.active span {
  background-position: center top;
}
.store .store-nav a:active:after, .store .store-nav a.active:after {
  border-color: #d50032 transparent transparent transparent;
}
.store .back-to-store {
  display: none;
}
.store .wrap-content {
  margin-top: -18px;
}
@media (min-width: 1280px) {
  .store .wrap-content {
    margin-top: 0;
  }
}
.store .store-locator .results-container {
  display: flex;
  flex-wrap: wrap;
}
.store .store-locator .store-locator-results {
  order: 1;
  width: 100%;
}
@media (min-width: 1024px) {
  .store .store-locator .store-locator-results {
    position: relative;
    padding-right: 20px;
    width: 33.3333333333%;
    height: 100vh;
  }
  .store .store-locator .store-locator-results:after {
    position: absolute;
    bottom: 0;
    height: 30px;
    width: 100%;
    background: linear-gradient(to top, white 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
  }
}
.store .store-locator .store-locator-map {
  order: 2;
  position: absolute;
  left: -9999rem;
  top: 0;
  width: 100%;
  height: 100vh;
  border: 1px solid #cccccc;
}
@media (min-width: 1024px) {
  .store .store-locator .store-locator-map {
    position: relative;
    left: auto;
    top: auto;
    width: 66.6666666667%;
    height: 100%;
  }
}
body.map-view.store .store-locator .store-locator-map {
  position: relative;
  left: auto;
}

.store .store-locator .store-locator-map .locator-map {
  width: 100%;
  height: 100vh;
}
.store .store-locator .store-locator-map .gm-style-iw-d h3 {
  font-size: 16px;
  margin: 0;
}
.store .store-locator .store-locator-map .gm-style-iw-d ul li {
  padding: 0;
  font-size: 13px;
}
.store .store-locator .store-locator-map .gm-style-iw-d ul li::before {
  content: none;
}
.store .store-locator .location {
  width: 100%;
}
.store .store-locator .open-modal {
  margin: 0 auto;
}
.store .store-locator .checkbox-list {
  position: relative;
  margin: 0 0 0 -6px;
  width: calc(100% + 12px);
  text-align: left;
  overflow: hidden;
}
.store .store-locator .checkbox-list li {
  display: inline-block;
  padding: 0 6px;
  width: 50%;
  float: left;
}
.store .store-locator .checkbox-list label {
  top: -6px;
  font-size: 14px;
  line-height: 14px;
  font-size: 0.875rem;
  line-height: 0.875rem;
  letter-spacing: normal;
}
.store .store-locator .view-type {
  float: left;
  margin-bottom: 20px;
}
.store .store-locator .view-type .view-list,
.store .store-locator .view-type .view-map {
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  line-height: 18px;
  font-size: 1.125rem;
  line-height: 1.125rem;
  letter-spacing: normal;
  color: #d50032;
}
@media (min-width: 1024px) {
  .store .store-locator .view-type .view-list,
  .store .store-locator .view-type .view-map {
    display: none;
  }
}
.store .store-locator .view-type .view-list span,
.store .store-locator .view-type .view-map span {
  display: inline-block;
  position: relative;
  top: 2px;
  margin: 0;
  width: 16px;
  height: 16px;
}
.store .store-locator .view-type .view-list span {
  background: url("../images/ICN-List-View.svg") center center/contain no-repeat;
}
.store .store-locator .view-type .view-map span {
  background: url("../images/ICN-Map-View.svg") center center/contain no-repeat;
}
body.map-view .view-type .view-map {
  display: none;
}

.store .store-locator .view-type .view-list {
  display: none;
}
body.map-view .view-type .view-list {
  display: inline-block;
}

.store .store-locator .btn-print {
  float: right;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  line-height: 18px;
  font-size: 1.125rem;
  line-height: 1.125rem;
  letter-spacing: normal;
  color: #d50032;
  border: none;
}
.store .store-locator .btn-print span {
  display: inline-block;
  position: relative;
  top: 1px;
  margin: 0;
  width: 16px;
  height: 16px;
  background: url("../images/ICN-Print.svg") center center/contain no-repeat;
}
.store .store-locator .store-locator-search {
  margin-bottom: 20px;
}
.store .store-locator .store-locator-search input[type=text] {
  margin-right: 6px;
  width: calc(100% - 60px);
  padding: 12px 10px 12px 46px;
  background: url("../images/ICN-Search-Single.svg") 20px center/18px 18px no-repeat;
  border: 1px solid #cccccc;
  border-radius: 10px;
}
@media (min-width: 1024px) {
  .store .store-locator .store-locator-search input[type=text] {
    width: calc(100% - 187px);
  }
}
.store .store-locator .store-locator-search .submit {
  display: inline-block;
  margin: 0;
  padding: 12px 10px;
  width: 50px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  color: #1a1a1a;
  border: 1px solid #cccccc;
  border-radius: 10px;
}
@media (min-width: 1024px) {
  .store .store-locator .store-locator-search .submit {
    width: 177px;
  }
}
.store .store-locator .store-locator-search .submit > span {
  display: inline-block;
  position: relative;
  top: 3px;
  width: 14px;
  height: 14px;
  background: url("../images/ICN-Filter.svg") center top/14px 28px no-repeat;
}
@media (min-width: 1024px) {
  .store .store-locator .store-locator-search .submit > span {
    top: 2px;
    margin-right: 5px;
  }
}
.store .store-locator .store-locator-search .submit span + span {
  display: none;
  top: auto;
  width: auto;
  height: auto;
  background: none;
}
@media (min-width: 1024px) {
  .store .store-locator .store-locator-search .submit span + span {
    display: inline-block;
  }
}
.store .store-locator .store-locator-search .submit:hover span, .store .store-locator .store-locator-search .submit:focus span {
  background-position: center bottom;
}
.store .store-locator .store-locator-search .ui-autocomplete {
  position: absolute;
  top: 93px;
  margin: 0;
  width: calc(100% - 51px);
  height: 206px;
  text-align: left;
  background: white;
  border: 1px solid #cccccc;
  border-radius: 10px;
  z-index: 101;
  overflow-y: scroll;
}
.store .store-locator .store-locator-search .ui-autocomplete li {
  padding: 0;
}
.store .store-locator .store-locator-search .ui-autocomplete li:before {
  display: none;
}
.store .store-locator .store-locator-search .ui-autocomplete li:last-child a {
  border-bottom: 0;
}
.store .store-locator .store-locator-search .ui-autocomplete a {
  display: block;
  padding: 5px 10px;
}
.store .store-locator .store-locator-search .ui-autocomplete a:hover, .store .store-locator .store-locator-search .ui-autocomplete a:focus {
  border-color: rgba(26, 26, 26, 0.15);
}
.store .store-locator .store-locator-results-number {
  display: none;
}
@media (min-width: 1024px) {
  .store .store-locator .results-list {
    height: 100%;
    overflow-y: scroll;
  }
}
.store .store-locator .result {
  position: relative;
  margin: 0 0 40px 0;
  padding: 60px 30px 0 30px;
  border: 1px solid #cccccc;
  border-radius: 10px;
}
.store .store-locator .result .distance {
  position: absolute;
  top: 24px;
  left: 30px;
  text-align: center;
}
.store .store-locator .result .distance .miles {
  display: block;
  padding-left: 20px;
  font-size: 14px;
  line-height: 18px;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: normal;
  text-transform: uppercase;
  background: url("../images/ICN-Pin-Red.svg") center left/14px 18px no-repeat;
}
.store .store-locator .result .favorite {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 45px;
  text-align: center;
  border-left: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  border-bottom-left-radius: 10px;
}
.store .store-locator .result .favorite span {
  background-color: none !important;
}
.store .store-locator .result .favorite input[type=checkbox] {
  border: 0;
  color: transparent;
}
.store .store-locator .result .favorite input[type=checkbox] + label {
  display: block;
  position: relative;
  font-size: 12px;
  line-height: 12px;
  font-size: 0.75rem;
  line-height: 0.75rem;
  letter-spacing: 0.05em;
  transition: background-position ease-in-out 0.2s;
}
.store .store-locator .result .favorite input[type=checkbox] + label:before {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  top: -4px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 22px;
  height: 19px;
  background: url("../images/ICN-Heart.svg") top left/22px 38px no-repeat;
  content: "";
}
.store .store-locator .result .favorite input[type=checkbox]:hover + label:before {
  background-position: bottom left;
  transition: background-position ease-in-out 0.2s;
}
.store .store-locator .result .favorite input[type=checkbox]:checked + label:before {
  background-position: bottom left;
}
.store .store-locator .result [itemprop=name] {
  margin: 0 0 4px 0;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 30px;
  line-height: 30px;
  font-size: 1.875rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  text-transform: none;
}
.store .store-locator .result [itemprop=name] a {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.store .store-locator .result [itemprop=address] {
  font-size: 18px;
  line-height: 24px;
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}
.store .store-locator .result .phone {
  display: block;
}
.store .store-locator .result .phone a {
  border: 0;
}
.store .store-locator .result .amenities,
.store .store-locator .result .fuels,
.store .store-locator .result .fuel-prices,
.store .store-locator .result .buttons {
  padding: 20px 0;
  border-top: 1px solid #cccccc;
}
.store .store-locator .result .amenities h3,
.store .store-locator .result .fuels h3,
.store .store-locator .result .fuel-prices h3 {
  margin-top: 0;
  font-size: 15px;
  line-height: 15px;
  font-size: 0.9375rem;
  line-height: 0.9375rem;
  letter-spacing: normal;
  text-transform: uppercase;
}
.store .store-locator .result .amenities ul,
.store .store-locator .result .fuels ul,
.store .store-locator .result .fuel-prices ul {
  position: relative;
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: normal;
  text-align: left;
}
.store .store-locator .result .amenities ul li,
.store .store-locator .result .fuels ul li,
.store .store-locator .result .fuel-prices ul li {
  display: inline-block;
  margin: 0 0 5px 0;
  padding: 6px 12px 6px 30px;
  border: 1px solid #cccccc;
  border-radius: 15px;
}
.store .store-locator .result .amenities ul li:before,
.store .store-locator .result .fuels ul li:before,
.store .store-locator .result .fuel-prices ul li:before {
  position: absolute;
  top: 6px;
  left: 10px;
  width: 14px;
  height: 16px;
  border-radius: 0;
  content: "";
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
}
.store .store-locator .result .amenities ul li.a-convenience-store:before,
.store .store-locator .result .fuels ul li.a-convenience-store:before,
.store .store-locator .result .fuel-prices ul li.a-convenience-store:before {
  background-image: url("../images/locator/convenience-store.svg");
}
.store .store-locator .result .amenities ul li.a-car-wash:before,
.store .store-locator .result .fuels ul li.a-car-wash:before,
.store .store-locator .result .fuel-prices ul li.a-car-wash:before {
  background-image: url("../images/locator/car-wash.svg");
}
.store .store-locator .result .amenities ul li.a-18-wheel-access:before,
.store .store-locator .result .fuels ul li.a-18-wheel-access:before,
.store .store-locator .result .fuel-prices ul li.a-18-wheel-access:before {
  background-image: url("../images/locator/18-wheel-access.svg");
}
.store .store-locator .result .amenities ul li.a-prepared-food:before,
.store .store-locator .result .fuels ul li.a-prepared-food:before,
.store .store-locator .result .fuel-prices ul li.a-prepared-food:before {
  background-image: url("../images/locator/food.svg");
}
.store .store-locator .result .amenities ul li.a-fast-stop-cards-only:before,
.store .store-locator .result .fuels ul li.a-fast-stop-cards-only:before,
.store .store-locator .result .fuel-prices ul li.a-fast-stop-cards-only:before {
  background-image: url("../images/locator/fast-stop-card.svg");
}
.store .store-locator .result .amenities ul li.a-atm:before,
.store .store-locator .result .fuels ul li.a-atm:before,
.store .store-locator .result .fuel-prices ul li.a-atm:before {
  background-image: url("../images/locator/atm.svg");
}
.store .store-locator .result .amenities ul li.a-scale:before,
.store .store-locator .result .fuels ul li.a-scale:before,
.store .store-locator .result .fuel-prices ul li.a-scale:before {
  background-image: url("../images/locator/scale.svg");
}
.store .store-locator .result .amenities ul li.a-restaurant:before,
.store .store-locator .result .fuels ul li.a-restaurant:before,
.store .store-locator .result .fuel-prices ul li.a-restaurant:before {
  background-image: url("../images/locator/restaurant.svg");
}
.store .store-locator .result .amenities ul li.a-credit-card-only:before,
.store .store-locator .result .fuels ul li.a-credit-card-only:before,
.store .store-locator .result .fuel-prices ul li.a-credit-card-only:before {
  background-image: url("../images/locator/card.svg");
}
.store .store-locator .result .amenities ul li.a-24-hours:before,
.store .store-locator .result .fuels ul li.a-24-hours:before,
.store .store-locator .result .fuel-prices ul li.a-24-hours:before {
  background-image: url("../images/locator/24-hour.svg");
}
.store .store-locator .result .amenities ul li.a-auto-service:before,
.store .store-locator .result .fuels ul li.a-auto-service:before,
.store .store-locator .result .fuel-prices ul li.a-auto-service:before {
  background-image: url("../images/locator/auto-service.svg");
}
.store .store-locator .result .amenities ul li.a-butcher:before,
.store .store-locator .result .fuels ul li.a-butcher:before,
.store .store-locator .result .fuel-prices ul li.a-butcher:before {
  background-image: url("../images/locator/butcher.svg");
}
.store .store-locator .result .amenities ul li.a-24-hour-pumps:before,
.store .store-locator .result .fuels ul li.a-24-hour-pumps:before,
.store .store-locator .result .fuel-prices ul li.a-24-hour-pumps:before {
  background-image: url("../images/locator/24-hour-gas.svg");
}
.store .store-locator .result .amenities ul li.a-car:before,
.store .store-locator .result .fuels ul li.a-car:before,
.store .store-locator .result .fuel-prices ul li.a-car:before {
  background-image: url("../images/locator/car.svg");
}
.store .store-locator .result .amenities ul li.a-car-care:before,
.store .store-locator .result .fuels ul li.a-car-care:before,
.store .store-locator .result .fuel-prices ul li.a-car-care:before {
  background-image: url("../images/locator/car-care.svg");
}
.store .store-locator .result .amenities ul li.a-def:before,
.store .store-locator .result .fuels ul li.a-def:before,
.store .store-locator .result .fuel-prices ul li.a-def:before {
  background-image: url("../images/locator/def.svg");
}
.store .store-locator .result .amenities ul li.a-farm-store:before,
.store .store-locator .result .fuels ul li.a-farm-store:before,
.store .store-locator .result .fuel-prices ul li.a-farm-store:before {
  background-image: url("../images/locator/farm-store.svg");
}
.store .store-locator .result .amenities ul li.a-food:before,
.store .store-locator .result .fuels ul li.a-food:before,
.store .store-locator .result .fuel-prices ul li.a-food:before {
  background-image: url("../images/locator/food.svg");
}
.store .store-locator .result .amenities ul li.a-gaming:before,
.store .store-locator .result .fuels ul li.a-gaming:before,
.store .store-locator .result .fuel-prices ul li.a-gaming:before {
  background-image: url("../images/locator/gaming.svg");
}
.store .store-locator .result .amenities ul li.a-pay-at-the-pump-only:before,
.store .store-locator .result .fuels ul li.a-pay-at-the-pump-only:before,
.store .store-locator .result .fuel-prices ul li.a-pay-at-the-pump-only:before {
  background-image: url("../images/locator/pay-at-pump.svg");
}
.store .store-locator .result .amenities ul li.a-kerosene:before,
.store .store-locator .result .fuels ul li.a-kerosene:before,
.store .store-locator .result .fuel-prices ul li.a-kerosene:before {
  background-image: url("../images/locator/kerosene.svg");
}
.store .store-locator .result .amenities ul li.a-no-ethanol-unleaded-gas:before,
.store .store-locator .result .fuels ul li.a-no-ethanol-unleaded-gas:before,
.store .store-locator .result .fuel-prices ul li.a-no-ethanol-unleaded-gas:before {
  background-image: url("../images/locator/no-ethanol.svg");
}
.store .store-locator .result .amenities ul li.a-liquid-propane:before,
.store .store-locator .result .fuels ul li.a-liquid-propane:before,
.store .store-locator .result .fuel-prices ul li.a-liquid-propane:before {
  background-image: url("../images/locator/liquid-propane.svg");
}
.store .store-locator .result .amenities ul li.a-gasoline:before,
.store .store-locator .result .fuels ul li.a-gasoline:before,
.store .store-locator .result .fuel-prices ul li.a-gasoline:before {
  background-image: url("../images/locator/gasoline.svg");
}
.store .store-locator .result .amenities ul li.a-diesel:before,
.store .store-locator .result .fuels ul li.a-diesel:before,
.store .store-locator .result .fuel-prices ul li.a-diesel:before {
  background-image: url("../images/locator/diesel.svg");
}
.store .store-locator .result .amenities ul li.a-def:before,
.store .store-locator .result .fuels ul li.a-def:before,
.store .store-locator .result .fuel-prices ul li.a-def:before {
  background-image: url("../images/locator/def.svg");
}
.store .store-locator .result .amenities ul li.a-e85:before,
.store .store-locator .result .fuels ul li.a-e85:before,
.store .store-locator .result .fuel-prices ul li.a-e85:before {
  background-image: url("../images/locator/e85.svg");
}
.store .store-locator .result .amenities ul li.a-liquid-propane:before,
.store .store-locator .result .fuels ul li.a-liquid-propane:before,
.store .store-locator .result .fuel-prices ul li.a-liquid-propane:before {
  background-image: url("../images/locator/liquid-propane.svg");
}
.store .store-locator .result .amenities ul li.a-propane-autogas:before,
.store .store-locator .result .fuels ul li.a-propane-autogas:before,
.store .store-locator .result .fuel-prices ul li.a-propane-autogas:before {
  background-image: url("../images/locator/propane-autogas.svg");
}
.store .store-locator .result .fuel-prices ul {
  padding-bottom: 15px;
}
.store .store-locator .result .fuel-prices ul li {
  padding: 7px 13px;
  margin-bottom: 15px;
}
.store .store-locator .result .fuel-prices .fuel-type,
.store .store-locator .result .fuel-prices .fuel-price {
  display: block;
  font-size: 14px;
  line-height: 18px;
}
.store .store-locator .result .fuel-prices .fuel-type::before,
.store .store-locator .result .fuel-prices .fuel-price::before {
  content: none;
}
.store .store-locator .result .fuel-prices .fuel-type {
  color: #d50032;
  font-weight: 700;
}
.store .store-locator .result .buttons {
  position: relative;
  left: -31px;
  padding: 0;
  width: calc(100% + 62px);
  text-align: center;
  overflow: hidden;
  border: 0;
}
.store .store-locator .result .directions,
.store .store-locator .result .details,
.store .store-locator .result .share {
  margin: 0;
  font-size: 14px;
  line-height: 24px;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.1em;
}
.store .store-locator .result .directions {
  position: relative;
  bottom: -1px;
  margin: -1px 0 0;
  padding: 21px 0;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  line-height: 18px;
  font-size: 1.125rem;
  line-height: 1.125rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  width: 100%;
}
.store .store-locator .result .details {
  float: left;
  padding: 17px 0;
  width: 50%;
  font-size: 16px;
  line-height: 16px;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 0.1em;
  color: #1a1a1a;
  background: #cccccc;
  border-right: 1px solid white;
  border-bottom-left-radius: 10px;
}
.store .store-locator .result .details:visited {
  color: #1a1a1a;
  background: #cccccc;
}
.store .store-locator .result .details:hover, .store .store-locator .result .details:focus {
  color: white;
  background: #1a1a1a;
}
.store .store-locator .result .share {
  float: right;
  padding: 17px 0;
  width: 50%;
  font-size: 16px;
  line-height: 16px;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 0.1em;
  color: #1a1a1a;
  background: #cccccc;
  border-bottom-right-radius: 10px;
}
.store .store-locator .result .share span {
  display: inline-block;
  margin-bottom: -2px;
  width: 16px;
  height: 15px;
  background: url("../images/ICN-Share.svg") center center/16px 15px no-repeat;
}
.store .store-locator .result .share:visited {
  color: #1a1a1a;
  background: #cccccc;
}
.store .store-locator .result .share:hover, .store .store-locator .result .share:focus {
  color: white;
  background: #1a1a1a;
}
.store .store-locator.route-a-trip .zip {
  width: 100%;
}
@media (min-width: 1024px) {
  .store .store-locator.route-a-trip .zip {
    display: flex;
  }
}
.store .store-locator.route-a-trip .location {
  width: 100%;
}
@media (min-width: 1024px) {
  .store .store-locator.route-a-trip .location {
    padding-right: 10px;
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .store .store-locator.route-a-trip .location input[type=text] {
    width: calc(100% - 60px);
  }
}
.store .store-locator.route-a-trip .destination {
  width: 100%;
}
@media (min-width: 1024px) {
  .store .store-locator.route-a-trip .destination {
    width: 50%;
  }
}
.store .store-locator.route-a-trip .swap {
  display: inline-block;
  margin: 10px auto;
  padding: 12px 10px;
  width: 50px;
  height: 44px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  color: #1a1a1a;
  border: 1px solid #cccccc;
  border-radius: 10px;
}
@media (min-width: 1024px) {
  .store .store-locator.route-a-trip .swap {
    margin: 0;
  }
}
.store .store-locator.route-a-trip .swap span {
  display: inline-block;
  position: relative;
  width: 19px;
  height: 16px;
  background: url("../images/ICN-Swap.svg") center top/19px 32px no-repeat;
}
.store .store-locator.route-a-trip .swap:hover span, .store .store-locator.route-a-trip .swap:focus span {
  background-position: center bottom;
}
.store .store-locator.route-a-trip .waypoints {
  margin-top: 10px;
}
.store .store-locator.route-a-trip .waypoints .added-to-route {
  display: block;
  margin-bottom: 5px;
}
.store .store-locator.route-a-trip .waypoints .added-to-route:last-child {
  margin-bottom: 0;
}
.store .store-locator.route-a-trip .waypoints .added-to-route .close {
  display: inline-block;
  position: relative;
  bottom: -3px;
  width: 16px;
  height: 16px;
  background: url("../images/ICN-Close-Red.svg") center center/contain no-repeat;
  border: 0;
}
.store .store-locator.route-a-trip .waypoints .added-to-route .close:hover, .store .store-locator.route-a-trip .waypoints .added-to-route .close:focus {
  background-image: url("../images/ICN-Close.svg");
}
.store .store-locator.route-a-trip .waypoints .added-to-route span {
  display: inline-block;
  padding-left: 5px;
  width: calc(100% - 16px);
}
.store .store-locator.route-a-trip .waypoints .added-to-route span br {
  content: ", ";
  margin-right: 6px;
}
.store .store-locator.route-a-trip .tbt {
  margin-top: 10px;
  margin-bottom: 0;
}
.store .store-locator.route-a-trip .store-locator-results {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.store .store-locator.route-a-trip .store-locator-search {
  position: relative;
  top: auto;
  left: auto;
}
.store .store-locator.route-a-trip .store-locator-search:after {
  top: auto;
  bottom: -30px;
  left: 0;
}
.store .store-locator.route-a-trip .store-locator-search .ui-autocomplete div {
  cursor: pointer;
  padding: 5px 10px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #d50032;
  border-bottom: 1px solid rgba(26, 26, 26, 0.15);
}
.store .store-locator.route-a-trip .store-locator-search .ui-autocomplete div:hover, .store .store-locator.route-a-trip .store-locator-search .ui-autocomplete div:focus {
  color: #1a1a1a;
  border-color: rgba(26, 26, 26, 0.15);
}
.store .store-locator.route-a-trip .store-locator-search .ui-autocomplete li:last-child div {
  border-bottom: 0;
}
.store .store-locator.route-a-trip .view-type {
  float: none;
}
.store .store-locator.route-a-trip .results-list {
  position: relative;
}
@media (min-width: 1024px) {
  .store .store-locator.route-a-trip .results-list {
    padding-top: 0;
    flex: 1;
    display: flex;
    overflow: auto;
  }
  .store .store-locator.route-a-trip .results-list .results-list-inner {
    min-height: min-content;
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
  .store .store-locator.route-a-trip .results-list .result {
    width: 100%;
  }
}

.store-detail {
  padding-top: 0;
  background: white;
}
.store-detail header.primary,
.store-detail footer.primary,
.store-detail .banner-wrapper,
.store-detail .page-title,
.store-detail .skipcontent {
  display: none;
}
.store-detail .content {
  padding: 0;
}
.store-detail .wrap-content {
  margin-top: 0;
}
.store-detail .store-nav {
  display: none;
}
.store-detail .back-to-store {
  display: none;
  position: relative;
  padding: 18px 0;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #1a1a1a;
  background: #cccccc;
  border: 0;
}
.store-detail .back-to-store .inner {
  display: block;
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 25px;
  width: 100%;
}
@media (min-width: 1280px) {
  .store-detail .back-to-store .inner {
    padding-left: 120px;
    padding-right: 120px;
    max-width: 1600px;
  }
}
.store-detail .back-to-store span:not(.inner) {
  display: inline-block;
  position: relative;
  top: 3px;
  margin-right: 10px;
  width: 18px;
  height: 16.5px;
  background: url("../images/ICN-Back.svg") center top/18px 33px no-repeat;
  transition: background-position 0.2s ease-in-out;
}
.store-detail .back-to-store:visited {
  color: #1a1a1a;
}
.store-detail .back-to-store:focus, .store-detail .back-to-store:hover {
  color: #d50032;
  text-decoration: none;
}
.store-detail .back-to-store:focus span:not(.inner), .store-detail .back-to-store:hover span:not(.inner) {
  background-position: center bottom;
  transition: background-position 0.2s ease-in-out;
}
.store-detail .name {
  margin: 0 0 50px 0;
  padding: 20px 0;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 30px;
  line-height: 30px;
  font-size: 1.875rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  border-bottom: 1px solid #cccccc;
}
.store-detail .name span {
  display: block;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 30px;
  font-size: 1.5rem;
  line-height: 1.875rem;
  letter-spacing: normal;
}
.store-detail .contact-info {
  margin: 0 0 40px 0;
}
.store-detail .contact-info li {
  padding: 0;
}
.store-detail .contact-info li:before {
  display: none;
}
.store-detail .contact-info .btn-directions {
  display: inline-block;
  margin: 10px 0 0;
  padding: 10px 20px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: 0;
  box-shadow: none;
  text-shadow: none;
  border-radius: 0;
  color: white;
  background: #d50032;
  transition: color 0.2s ease-in-out, background 0.2s ease-in-out;
}
.store-detail .contact-info .btn-directions:visited, .store-detail .contact-info .btn-directions:active {
  color: white;
  background: #d50032;
}
.store-detail .contact-info .btn-directions:hover, .store-detail .contact-info .btn-directions:focus {
  color: white;
  background: #1a1a1a;
}
.store-detail .three-column {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
@media (min-width: 640px) {
  .store-detail .three-column {
    margin-left: -20px;
    width: calc(100% + 40px);
  }
}
.store-detail .three-column .col {
  margin: 0 0 40px 0;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 10px;
}
@media (min-width: 640px) {
  .store-detail .three-column .col {
    margin-left: 20px;
    margin-right: 20px;
    width: calc((100% - 80px) / 2);
  }
}
@media (min-width: 1024px) {
  .store-detail .three-column .col {
    width: calc((100% - 120px) / 3);
  }
}
.store-detail .three-column h3 {
  margin: 0;
  padding: 20px 35px;
  border-bottom: 1px solid #cccccc;
}
.store-detail .three-column ul {
  margin: 0;
  padding: 20px 35px;
}
.store-detail .three-column ul li {
  padding: 0;
}
.store-detail .three-column ul li:before {
  display: none;
}
.store-detail .three-column ul.services-list li {
  position: relative;
  padding-left: 35px;
  padding-bottom: 4px;
}
.store-detail .three-column ul.services-list li:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 30px;
  content: "";
  border-radius: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
}
.store-detail .three-column ul.services-list.amenities li.a-convenience-store:before {
  background-image: url("../images/locator/convenience-store.svg");
}
.store-detail .three-column ul.services-list.amenities li.a-car-wash:before {
  background-image: url("../images/locator/car-wash.svg");
}
.store-detail .three-column ul.services-list.amenities li.a-18-wheel-access:before {
  background-image: url("../images/locator/18-wheel-access.svg");
}
.store-detail .three-column ul.services-list.amenities li.a-prepared-food:before {
  background-image: url("../images/locator/food.svg");
}
.store-detail .three-column ul.services-list.amenities li.a-fast-stop-cards-only:before {
  background-image: url("../images/locator/fast-stop-card.svg");
}
.store-detail .three-column ul.services-list.amenities li.a-atm:before {
  background-image: url("../images/locator/atm.svg");
}
.store-detail .three-column ul.services-list.amenities li.a-scale:before {
  background-image: url("../images/locator/scale.svg");
}
.store-detail .three-column ul.services-list.amenities li.a-restaurant:before {
  background-image: url("../images/locator/restaurant.svg");
}
.store-detail .three-column ul.services-list.amenities li.a-credit-card-only:before {
  background-image: url("../images/locator/card.svg");
}
.store-detail .three-column ul.services-list.amenities li.a-24-hours:before {
  background-image: url("../images/locator/24-hour.svg");
}
.store-detail .three-column ul.services-list.amenities li.a-auto-service:before {
  background-image: url("../images/locator/auto-service.svg");
}
.store-detail .three-column ul.services-list.amenities li.a-butcher:before {
  background-image: url("../images/locator/butcher.svg");
}
.store-detail .three-column ul.services-list.amenities li.a-24-hour-pumps:before {
  background-image: url("../images/locator/24-hour-gas.svg");
}
.store-detail .three-column ul.services-list.amenities li.a-car:before {
  background-image: url("../images/locator/car.svg");
}
.store-detail .three-column ul.services-list.amenities li.a-car-care:before {
  background-image: url("../images/locator/car-care.svg");
}
.store-detail .three-column ul.services-list.amenities li.a-def:before {
  background-image: url("../images/locator/def.svg");
}
.store-detail .three-column ul.services-list.amenities li.a-farm-store:before {
  background-image: url("../images/locator/farm-store.svg");
}
.store-detail .three-column ul.services-list.amenities li.a-food:before {
  background-image: url("../images/locator/food.svg");
}
.store-detail .three-column ul.services-list.amenities li.a-gaming:before {
  background-image: url("../images/locator/gaming.svg");
}
.store-detail .three-column ul.services-list.amenities li.a-pay-at-the-pump-only:before {
  background-image: url("../images/locator/pay-at-pump.svg");
}
.store-detail .three-column ul.services-list.amenities li.a-kerosene:before {
  background-image: url("../images/locator/kerosene.svg");
}
.store-detail .three-column ul.services-list.amenities li.a-no-ethanol-unleaded-gas:before {
  background-image: url("../images/locator/no-ethanol.svg");
}
.store-detail .three-column ul.services-list.amenities li.a-liquid-propane:before {
  background-image: url("../images/locator/liquid-propane.svg");
}
.store-detail .three-column ul.services-list.fuels li.a-gasoline:before {
  background-image: url("../images/locator/gasoline.svg");
}
.store-detail .three-column ul.services-list.fuels li.a-diesel:before {
  background-image: url("../images/locator/diesel.svg");
}
.store-detail .three-column ul.services-list.fuels li.a-def:before {
  background-image: url("../images/locator/def.svg");
}
.store-detail .three-column ul.services-list.fuels li.a-e85:before {
  background-image: url("../images/locator/e85.svg");
}
.store-detail .three-column ul.services-list.fuels li.a-liquid-propane:before {
  background-image: url("../images/locator/liquid-propane.svg");
}
.store-detail .three-column ul.services-list.fuels li.a-propane-autogas:before {
  background-image: url("../images/locator/propane-autogas.svg");
}
.store-detail .three-column ul.services-list.payments li {
  position: relative;
  padding-left: 45px;
  padding-bottom: 4px;
}
.store-detail .three-column ul.services-list.payments li:before {
  display: block;
  position: absolute;
  top: 3px;
  left: 0;
  width: 40px;
  height: 25px;
  content: "";
  border-radius: 0;
}
.store-detail .three-column ul.services-list.payments li:before {
  background: url("../images/sprite-payments.png") top center/40px 313px no-repeat;
}
.store-detail .three-column ul.services-list.payments li.a-american-express:before {
  background-position: center 0;
}
.store-detail .three-column ul.services-list.payments li.a-discover:before {
  background-position: center -41px;
}
.store-detail .three-column ul.services-list.payments li.a-fast-stop-cards:before {
  background-position: center -82px;
}
.store-detail .three-column ul.services-list.payments li.a-gift-card:before {
  background-position: center -124px;
}
.store-detail .three-column ul.services-list.payments li.a-mastercard:before {
  background-position: center -165px;
}
.store-detail .three-column ul.services-list.payments li.a-visa:before {
  background-position: center -206px;
}
.store-detail .three-column ul.services-list.payments li.a-voyager:before {
  background-position: center -247px;
}
.store-detail .three-column ul.services-list.payments li.a-wright-express:before {
  background-position: center -288px;
}

.online-store .user {
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #b2b2b2;
  overflow: hidden;
}
.online-store .user .username {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 20px;
  font-size: 1.25rem;
  line-height: 1.25rem;
  letter-spacing: normal;
  float: left;
}
.online-store .user .cart {
  position: relative;
  float: right;
}
.online-store .user .cart .count:empty {
  display: inline-block;
  width: 22px;
  height: 19px;
  background: url("../images/ICN-Cart.svg") center center/contain no-repeat;
}
.online-store .user .cart .count a {
  display: inline-block;
  padding-top: 2px;
  width: 20px;
  height: 20px;
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 13px;
  font-size: 0.8125rem;
  line-height: 0.8125rem;
  letter-spacing: normal;
  color: white;
  background: #d50032;
  border: 0;
  border-radius: 50%;
}
.online-store .user .cart .count a:before {
  display: inline-block;
  position: absolute;
  bottom: -5px;
  right: 22px;
  width: 22px;
  height: 19px;
  background: url("../images/ICN-Cart.svg") center left/contain no-repeat;
  content: "";
}
.online-store .user .cart .count a:hover, .online-store .user .cart .count a:focus {
  background: #404040;
  border: 0;
}
.online-store .product {
  position: relative;
  margin-bottom: 20px;
  padding: 20px 20px 64px 20px;
  border: 1px solid #cccccc;
}
.online-store .product .photo {
  margin-bottom: 10px;
}
.online-store .product .name,
.online-store .product .size,
.online-store .product .per,
.online-store .product .price,
.online-store .product .qty {
  display: block;
}
.online-store .product .name {
  margin-top: 0;
}
.online-store .product .id {
  display: block;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 14px;
  line-height: 14px;
  font-size: 0.875rem;
  line-height: 0.875rem;
  letter-spacing: normal;
}
.online-store .product .modal img {
  margin: 0 auto;
}
.online-store .product .btn-primary {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.modal-overlay {
  background: rgba(0, 0, 0, 0.8);
}

.modal {
  border-radius: 10px;
}
.modal:focus {
  border-color: #e6e6e6;
  outline: 0;
}
.modal .content-form {
  text-align: left;
}
.modal .modal-content {
  padding: 100px 25px 25px 25px;
}
@media (min-width: 640px) {
  .modal .modal-content {
    padding: 100px 60px 60px 60px;
  }
}
.modal .modal-content h1 {
  position: absolute;
  top: -1px;
  left: 0;
  margin: 0;
  padding: 25px;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 30px;
  line-height: 30px;
  font-size: 1.875rem;
  line-height: 1.875rem;
  letter-spacing: 0.025em;
  color: white;
  text-align: left;
  background-color: #d50032;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 5;
}
.modal .modal-content h1:after {
  position: absolute;
  top: 0;
  right: -36px;
  width: 50%;
  height: 100%;
  background: url("../images/ICN-Logo-Faint@2x.png") top right/contain no-repeat;
  content: "";
}
.modal .close-modal {
  position: absolute;
  top: 0;
  right: -1px;
  width: 40px;
  height: 40px;
  background: transparent;
  z-index: 99;
}
.modal .close-modal::before {
  content: "\f00d";
  position: relative;
  background: transparent;
  color: black;
  font-size: 28px;
  font-family: "Font Awesome 5 Pro", "Font Awesome 5 Free", FontAwesome;
  font-weight: 900;
  top: 10px;
}
.modal .close-modal:hover, .modal .close-modal:focus {
  background: transparent;
}
.modal .close-modal:hover::before, .modal .close-modal:focus::before {
  color: white;
}

.ui-dialog {
  background: white;
  border-radius: 10px;
  z-index: 1;
}
.ui-dialog .ui-dialog-titlebar {
  position: relative;
  padding: 10px 20px;
  font-size: 24px;
  line-height: 24px;
  font-size: 1.5rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  background: black;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: white;
  border-top-left-radius: 10px;
}
.ui-dialog .ui-dialog-titlebar-close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0px;
  padding: 14px 18px;
  height: 100%;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  font-size: 0.875rem;
  line-height: 0.875rem;
  letter-spacing: normal;
  color: white;
  background: #d50032;
  border: 0;
}
.ui-dialog .ui-dialog-titlebar-close:hover, .ui-dialog .ui-dialog-titlebar-close:focus {
  color: white;
  background: #1a1a1a;
}
.ui-dialog .ui-dialog-content {
  padding: 40px 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.ui-dialog .ui-dialog-buttonpane {
  padding: 10px 20px;
  background: #cccccc;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.ui-dialog .ui-dialog-buttonpane .dnnPrimaryAction,
.ui-dialog .ui-dialog-buttonpane .dnnSecondaryAction {
  margin-bottom: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 16px;
  line-height: 24px;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}
.ui-dialog .ui-dialog-buttonpane .dnnSecondaryAction {
  float: right;
}

.ui-widget-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1071;
}

ul.share-buttons:not(.dnnActions) {
  display: flex;
  justify-content: space-evenly;
  margin: 0;
  padding: 0;
  list-style: none;
}
ul.share-buttons:not(.dnnActions) li {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: calc(33.3333333333% - 6px);
}
ul.share-buttons:not(.dnnActions) li:before {
  display: none;
}
ul.share-buttons:not(.dnnActions) [class*=btn-] {
  display: block;
  position: relative;
  padding: 4px 5px 8px;
  font-size: 12px;
  line-height: 12px;
  font-size: 0.75rem;
  line-height: 0.75rem;
  letter-spacing: 0.05em;
  font-weight: 500;
  color: white;
  border-radius: 3px;
}
ul.share-buttons:not(.dnnActions) [class*=btn-] span {
  position: relative;
  top: 3px;
  display: inline-block;
  margin-right: 6px;
  width: 16px;
  height: 16px;
  background-position: center center;
  background-position: center center;
  background-repeat: no-repeat;
}
ul.share-buttons:not(.dnnActions) [class*=facebook] {
  background: #3b5998;
}
ul.share-buttons:not(.dnnActions) [class*=facebook]:visited, ul.share-buttons:not(.dnnActions) [class*=facebook]:active {
  background: #3b5998;
}
ul.share-buttons:not(.dnnActions) [class*=facebook]:hover, ul.share-buttons:not(.dnnActions) [class*=facebook]:focus {
  background: #2d4373;
}
ul.share-buttons:not(.dnnActions) [class*=facebook] span {
  background-image: url("../images/social/facebook.svg");
}
ul.share-buttons:not(.dnnActions) [class*=twitter] {
  background: #1da1f2;
}
ul.share-buttons:not(.dnnActions) [class*=twitter]:visited, ul.share-buttons:not(.dnnActions) [class*=twitter]:active {
  background: #1da1f2;
}
ul.share-buttons:not(.dnnActions) [class*=twitter]:hover, ul.share-buttons:not(.dnnActions) [class*=twitter]:focus {
  background: #0c85d0;
}
ul.share-buttons:not(.dnnActions) [class*=twitter] span {
  background-image: url("../images/social/twitter.svg");
}
ul.share-buttons:not(.dnnActions) [class*=linkedin] {
  background: #0077b5;
}
ul.share-buttons:not(.dnnActions) [class*=linkedin]:visited, ul.share-buttons:not(.dnnActions) [class*=linkedin]:active {
  background: #0077b5;
}
ul.share-buttons:not(.dnnActions) [class*=linkedin]:hover, ul.share-buttons:not(.dnnActions) [class*=linkedin]:focus {
  background: #005582;
}
ul.share-buttons:not(.dnnActions) [class*=linkedin] span {
  background-image: url("../images/social/linkedin.svg");
}
ul.share-buttons:not(.dnnActions) [class*=xing] {
  background: #1a7576;
}
ul.share-buttons:not(.dnnActions) [class*=xing]:visited, ul.share-buttons:not(.dnnActions) [class*=xing]:active {
  background: #1a7576;
}
ul.share-buttons:not(.dnnActions) [class*=xing]:hover, ul.share-buttons:not(.dnnActions) [class*=xing]:focus {
  background: #114c4c;
}
ul.share-buttons:not(.dnnActions) [class*=xing] span {
  background-image: url("../images/social/xing.svg");
}
ul.share-buttons:not(.dnnActions) [class*=text] {
  background: #0bbc28;
}
ul.share-buttons:not(.dnnActions) [class*=text]:visited, ul.share-buttons:not(.dnnActions) [class*=text]:active {
  background: #0bbc28;
}
ul.share-buttons:not(.dnnActions) [class*=text]:hover, ul.share-buttons:not(.dnnActions) [class*=text]:focus {
  background: #088c1e;
}
ul.share-buttons:not(.dnnActions) [class*=text] span {
  background-image: url("../images/social/text.svg");
}
ul.share-buttons:not(.dnnActions) [class*=mail] {
  background: #dc4b3f;
}
ul.share-buttons:not(.dnnActions) [class*=mail]:visited, ul.share-buttons:not(.dnnActions) [class*=mail]:active {
  background: #dc4b3f;
}
ul.share-buttons:not(.dnnActions) [class*=mail]:hover, ul.share-buttons:not(.dnnActions) [class*=mail]:focus {
  background: #c43024;
}
ul.share-buttons:not(.dnnActions) [class*=mail] span {
  background-image: url("../images/social/mail.svg");
}
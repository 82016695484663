// modal
.modal-overlay {
  background: rgba(black, 0.8);
}

.modal {
  // border: 1px solid $silver;
  border-radius: 10px;

  &:focus {
    border-color: $mercury;
    outline: 0;
  }

  .content-form {
    text-align: left;
  }

  .modal-content {
    // position: relative;
    padding: 100px 25px 25px 25px;
    @include medium-up {
      padding: 100px 60px 60px 60px;
    }

    h1 {
      position: absolute;
      top: -1px;
      // top: -2px;
      // left: -1px;
      left: 0;
      margin: 0;
      padding: 25px;
      // width: calc(100% + 2px);
      width: 100%;
      @include franklin-demi;
      @include fontsize(30 30 25);
      color: white;
      text-align: left;
      background-color: $monza;
      // background: $monza url('../images/ICN-Logo-Faint@2x.png') top right / contain no-repeat;
      // background-position: 100% 0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      // overflow: hidden;
      z-index: 5;

      &:after {
        position: absolute;
        top: 0;
        right: -36px;
        width: 50%;
        height: 100%;
        background: url('../images/ICN-Logo-Faint@2x.png') top right / contain no-repeat;
        content: '';
      }
    }

    // #locationDetailFrame {
    //   height: 500px;
    // }
  }

  .close-modal {
    position: absolute;
    // top: -42px;
    top: 0;
    right: -1px;
    width: 40px;
    height: 40px;
    // background: url('../images/ICN-Close-Modal.png') center center / 20px 20px no-repeat;
    background: transparent;
    z-index: 99;

    &::before {
      content: '\f00d';
      position: relative;
      background: transparent;
      color: black;
      font-size: 28px;
      font-family: 'Font Awesome 5 Pro', 'Font Awesome 5 Free', FontAwesome;
      font-weight: 900;
      top: 10px;
    }

    &:hover,
    &:focus {
      background: transparent;

      &::before {
        color: white;
      }
    }
  }
}

.ui-dialog {
  background: white;
  border-radius: 10px;
  // overflow: hidden;
  z-index: 1;

  .ui-dialog-titlebar {
    position: relative;
    padding: 10px 20px;
    @include fontsize(24 24);
    background: black;
    @include franklin-demi;
    color: white;
    border-top-left-radius: 10px;
  }

  .ui-dialog-titlebar-close {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0px;
    padding: 14px 18px;
    height: 100%;
    // font-family: 'Roboto Condensed',sans-serif;
    // font-weight: 500;
    // font-style: normal;
    @include franklin-cond-med;
    @include fontsize(14 14);
    color: white;
    background: $monza;
    border: 0;

    &:hover,
    &:focus {
      color: white;
      background: $codgray;
    }
  }

  .ui-dialog-content {
    padding: 40px 20px;
    // font-family: 'Roboto',sans-serif;
    // font-weight: 400;
    // font-style: normal;
    @include franklin-book;
  }

  .ui-dialog-buttonpane {
    padding: 10px 20px;
    background: #cccccc;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;

    .dnnPrimaryAction,
    .dnnSecondaryAction {
      margin-bottom: 0;
      padding-top: 4px;
      padding-bottom: 4px;
      @include fontsize(16 24);
    }

    .dnnSecondaryAction {
      float: right;
    }
  }
}

.ui-widget-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(black, 0.8);
  z-index: 1071;
}
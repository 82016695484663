// online store
.online-store {
  .user {
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid $silverchalice;
    overflow: hidden;

    .username {
      @include franklin-cond-med;
      @include fontsize(20 20);
      float: left;
    }

    .cart {
      position: relative;
      float: right;

      .count {
        &:empty {
          display: inline-block;
          width: 22px;
          height: 19px;
          background: url('../images/ICN-Cart.svg') center center / contain no-repeat;
        }

        a {
          display: inline-block;
          padding-top: 2px;
          width: 20px;
          height: 20px;
          text-align: center;
          @include franklin-cond-book;
          @include fontsize(13 13);
          color: white;
          background: $monza;
          border: 0;
          border-radius: 50%;

          &:before {
            display: inline-block;
            position: absolute;
            bottom: -5px;
            right: 22px;
            width: 22px;
            height: 19px;
            background: url('../images/ICN-Cart.svg') center left / contain no-repeat;
            content: '';
          }

          &:hover,
          &:focus {
            background: $tundora;
            border: 0;
          }
        }
      }
    }
  }

  .search {
  }

  .product {
    position: relative;
    margin-bottom: 20px;
    padding: 20px 20px 64px 20px;
    border: 1px solid $silver;

    .photo {
      margin-bottom: 10px;
    }

    .name,
    .size,
    .per,
    .price,
    .qty {
      display: block;
    }

    .name {
      margin-top: 0;
    }

    .id {
      display: block;
      @include franklin-light-it;
      @include fontsize(14 14);
    }

    .open-modal {
      // display: block;
      // max-height: 50%;
    }

    .modal img {
      margin: 0 auto;
    }

    .details {
      // max-height: 50%;
    }

    .btn-primary {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
}
table.responsive-table {
  .cell-heading {
    display: none;
  }
}

@include mq(0 $large) {
  table.responsive-table {
    width: 100%;

    thead tr {
      display: none;
    }

    td {
      display: block;
      clear: left;
    }

    .cell-heading {
      display: block;
    }

    .cell-content {
      display: block;
    }
  }
}

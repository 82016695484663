// store locator
.store {
  .page-title {
    top: -160px;
    @include xlarge-up {
      top: -256px;
    }
  }

  .page-title {
  }

  .store-nav {
    display: flex;
    // position: absolute;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    // margin-bottom: 40px;
    // margin-bottom: 15px;
    // border-bottom: 1px solid $silver;
    // @include medium-up {
    //   display: flex;
    // }

    a {
      display: inline-block;
      position: relative;
      // padding: 12px 15px;
      padding: 12px 0;
      // width: 100%;
      width: 50%;
      @include franklin-demi;
      @include fontsize(18 30);
      color: $codgray;
      text-transform: none;
      text-align: center;
      // border-left: 1px solid $silver;
      // border-top: 1px solid $silver;
      // border-right: 1px solid $silver;
      // border-top-left-radius: 10px;
      // border-top-right-radius: 10px;
      border-bottom: 0;
      // background: white;
      background: $silver;
      // @include medium-up {
      //   width: 50%;
      // }

      &:after {
        // display: none;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 12px 12px 0 12px;
        border-color: $silver transparent transparent transparent;
        content: '';
        z-index: 100;
        // @include medium-up {
        //   display: block;
        // }
      }

      span {
        display: inline-block;
        position: relative;
        bottom: -4px;
        margin-right: 5px;
        transition: background-position ease-in-out 0.2s;
      }

      &:first-child span {
        width: 18px;
        height: 22.5px;
        background: url('../images/ICN-Locations-Pin.svg') center bottom / 18px 45px no-repeat;
      }

      &:last-child span {
        width: 22px;
        height: 25px;
        background: url('../images/ICN-Route-Pin.svg') center bottom / 22px 50px no-repeat;
      }

      &:hover,
      &:focus {
        color: white;
        background: $monza;

        // border-color: $monza;

        &:first-child span {
          background-position: center top;
        }

        &:last-child span {
          background-position: center top;
        }

        &:after {
          // border-color: $monza;
          border-color: $monza transparent transparent transparent;
        }
      }

      &:active,
      &.active {
        color: white;
        background: $monza;
        // border-color: $monza;
        span {
          background-position: center top;
        }

        &:after {
          // border-color: $monza;
          border-color: $monza transparent transparent transparent;
        }
      }
    }
  }

  .back-to-store {
    display: none;
  }

  .wrap-content {
    margin-top: -18px;
    @include xlarge-up {
      margin-top: 0;
    }
  }

  .store-locator {
    // margin-top: 60px;
    @include large-up {
      // margin-top: 72px;
    }

    .results-container {
      // @include large-up {
      display: flex;
      flex-wrap: wrap;
      // }
    }

    .store-locator-results {
      order: 1;
      width: 100%;
      @include large-up {
        // order: 1;
        position: relative;
        padding-right: 20px;
        // padding-bottom: 40px;
        width: calc(100% / 3);
        height: 100vh;

        &:after {
          position: absolute;
          bottom: 0;
          height: 30px;
          width: 100%;
          background: linear-gradient(to top, rgba(white, 1) 0%, rgba(white, 0) 100%);
          content: '';
        }
      }
    }

    .store-locator-map {
      order: 2;
      position: absolute;
      left: -9999rem;
      top: 0;
      width: 100%;
      height: 100vh;
      border: 1px solid $silver;
      @include large-up {
        // order: 2;
        position: relative;
        left: auto;
        top: auto;
        width: calc(100% / 3 * 2);
        height: 100%;
      }

      @at-root body.map-view.store .store-locator .store-locator-map{
        position: relative;
        left: auto;
      }

      @at-root body.map-view .content{
        // padding-bottom: 0;
      }

      .locator-map {
        width: 100%;
        height: 100vh;
      }

      .gm-style-iw-d {
        h3 {
          font-size: 16px;
          margin: 0;
        }

        ul {
          li {
            padding: 0;
            font-size: 13px;

            &::before {
              content: none;
            }
          }
        }
      }
    }

    .zip {
      // text-align: center;
      @include large-up {
      }
    }

    .location {
      width: 100%;
    }

    .open-modal {
      margin: 0 auto;
    }

    .checkbox-list {
      // display: flex;
      position: relative;
      margin: 0 0 0 -6px;
      width: calc(100% + 12px);
      text-align: left;
      overflow: hidden;

      li {
        display: inline-block;
        padding: 0 6px;
        width: calc(100% / 2);
        float: left;
      }

      label {
        top: -6px;
        @include fontsize(14 14);
      }
    }

    // [class*='view'] {
    //   position: absolute;
    //   top: 86px;
    //   right: 15px;
    //   @include fontsize(12 12);

    //   span {
    //     display: block;
    //     margin: 0 auto;
    //     width: 16px;
    //     height: 16px;
    //   }
    // }

    .view-type {
      float: left;
      margin-bottom: 20px;

      .view-list,
      .view-map {
        cursor: pointer;
        @include franklin-demi;
        @include fontsize(18 18);
        color: $monza;
        @include large-up {
          display: none;
        }

        span {
          display: inline-block;
          position: relative;
          top: 2px;
          margin: 0;
          width: 16px;
          height: 16px;
        }
      }

      .view-list span {
        background: url('../images/ICN-List-View.svg') center center / contain no-repeat;
      }

      .view-map span {
        background: url('../images/ICN-Map-View.svg') center center / contain no-repeat;
      }

      @at-root body.map-view .view-type .view-map{
        display: none;
      }

      .view-list {
        display: none;
      }

      @at-root body.map-view .view-type .view-list{
        display: inline-block;
      }
    }

    .btn-print {
      float: right;
      margin-bottom: 20px;
      @include franklin-demi;
      @include fontsize(18 18);
      color: $monza;
      border: none;
      // margin-bottom: 10px;

      span {
        display: inline-block;
        position: relative;
        top: 1px;
        margin: 0;
        width: 16px;
        height: 16px;
        background: url('../images/ICN-Print.svg') center center / contain no-repeat;
      }
    }

    .store-locator-search {
      margin-bottom: 20px;
      @include large-up {
        // position: absolute;
        // top: 0;
        // left: 0;
        // margin: 0;
        // width: calc(100% - 20px);
        // background: white;
        // z-index: 100;

        &:after {
          // position: absolute;
          // top: 100%;
          // height: 30px;
          // width: 100%;
          // background: linear-gradient(to bottom, rgba(white, 1) 0%, rgba(white, 0) 100%);
          // content: '';
        }
      }

      @at-root .map{
        // margin-top: 354px;
      }

      input[type='text'] {
        // margin-bottom: 15px;
        margin-right: 6px;
        width: calc(100% - 60px);
        padding: 12px 10px 12px 46px;
        background: url('../images/ICN-Search-Single.svg') 20px center / 18px 18px no-repeat;
        border: 1px solid $silver;
        border-radius: 10px;
        @include large-up {
          width: calc(100% - 187px);
        }
      }

      .submit {
        display: inline-block;
        margin: 0;
        padding: 12px 10px;
        width: 50px;
        @include franklin-book;
        text-transform: uppercase;
        text-align: center;
        white-space: nowrap;
        color: $codgray;
        // width: 50px;
        // height: 50px;
        border: 1px solid $silver;
        border-radius: 10px;
        @include large-up {
          width: 177px;
        }

        > span {
          display: inline-block;
          position: relative;
          top: 3px;
          width: 14px;
          height: 14px;
          background: url('../images/ICN-Filter.svg') center top / 14px 28px no-repeat;
          // display: none;
          @include large-up {
            top: 2px;
            margin-right: 5px;
          }
        }

        span + span {
          display: none;
          top: auto;
          width: auto;
          height: auto;
          background: none;
          @include large-up {
            display: inline-block;
          }
        }

        &:hover,
        &:focus {
          span {
            background-position: center bottom;
          }
        }
      }

      .ui-autocomplete {
        position: absolute;
        top: 93px;
        margin: 0;
        width: calc(100% - 51px);
        height: 206px;
        text-align: left;
        background: white;
        border: 1px solid $silver;
        border-radius: 10px;
        z-index: 101;
        overflow-y: scroll;

        li {
          padding: 0;

          &:before {
            display: none;
          }

          &:last-child a {
            border-bottom: 0;
          }
        }

        a {
          display: block;
          padding: 5px 10px;

          &:hover,
          &:focus {
            border-color: rgba($codgray, 0.15);
          }
        }
      }
    }

    .store-locator-results-number {
      display: none;
    }

    .results-list {
      @include large-up {
        // padding-top: 144px;
        height: 100%;
        overflow-y: scroll;
      }
    }

    .result {
      position: relative;
      margin: 0 0 40px 0;
      padding: 60px 30px 0 30px;
      border: 1px solid $silver;
      border-radius: 10px;

      &:last-child {
        // margin-bottom: 0;
      }

      .distance {
        position: absolute;
        top: 24px;
        left: 30px;
        text-align: center;

        // .fas {
        //   font-size: 20px;
        // }

        .miles {
          display: block;
          // position: relative;
          padding-left: 20px;
          @include fontsize(14 18);
          text-transform: uppercase;
          background: url('../images/ICN-Pin-Red.svg') center left / 14px 18px no-repeat;
          // overflow: visible;
        }
      }

      .favorite {
        position: absolute;
        top: 0;
        right: 0;
        width: 45px;
        height: 45px;
        text-align: center;
        border-left: 1px solid $silver;
        border-bottom: 1px solid $silver;
        border-bottom-left-radius: 10px;

        span {
          background-color: none !important;
        }

        // input[type='checkbox']:before {
        //   display: inline-block;
        //   height: 20px;
        //   font-style: normal;
        //   font-variant: normal;
        //   text-rendering: auto;
        //   -webkit-font-smoothing: antialiased;
        //   font-family: 'Font Awesome 5 Free';
        //   font-weight: 400;
        //   font-size: 20px;
        //   content: '\f004';
        //   color: $codgray;
        // }

        // input[type='checkbox']:checked:before {
        //   font-weight: 900;
        //   content: '\f004';
        //   color: $monza;
        // }

        input[type='checkbox'] {
          border: 0;
          color: transparent;

          + label {
            display: block;
            position: relative;
            @include fontsize(12 12 50);
            transition: background-position ease-in-out 0.2s;

            &:before {
              cursor: pointer;
              display: inline-block;
              position: absolute;
              // top: -15px;
              top: -4px;
              left: 0;
              right: 0;
              margin: 0 auto;
              width: 22px;
              height: 19px;
              background: url('../images/ICN-Heart.svg') top left / 22px 38px no-repeat;
              // font-style: normal;
              // font-variant: normal;
              // text-rendering: auto;
              // -webkit-font-smoothing: antialiased;
              // font-family: 'Font Awesome 5 Free';
              // font-weight: 400;
              // font-size: 20px;
              // content: '\f004';
              // color: $codgray;
              content: '';
            }
          }

          &:hover + label:before {
            background-position: bottom left;
            transition: background-position ease-in-out 0.2s;
          }

          &:checked + label:before {
            // content: '\f004';
            // font-weight: 700;
            // content:"\f004";
            // color: $monza;
            background-position: bottom left;
          }
        }
      }

      [itemprop='name'],
      [itemprop='address'] {
        // text-align: center;
      }

      [itemprop='name'] {
        margin: 0 0 4px 0;
        // padding: 0 20px;
        @include franklin-cond-med;
        @include fontsize(30 30);
        text-transform: none;

        a {
          @include franklin-cond-med;
        }
      }

      [itemprop='address'] {
        @include fontsize(18 24);
      }

      .phone {
        display: block;

        a {
          border: 0;
        }
      }

      .amenities,
      .fuels,
      .fuel-prices,
      .buttons {
        padding: 20px 0;
        border-top: 1px solid $silver;
      }

      .amenities,
      .fuels,
      .fuel-prices {
        h3 {
          margin-top: 0;
          @include fontsize(15 15);
          text-transform: uppercase;
        }

        ul {
          position: relative;
          margin: 0;
          // margin-left: -6px;
          // width: calc(100% + 12px);
          @include fontsize(14 16);
          text-align: left;
          // overflow: hidden;

          li {
            display: inline-block;
            margin: 0 0 5px 0;
            padding: 6px 12px 6px 30px;
            border: 1px solid $silver;
            border-radius: 15px;

            &:before {
              position: absolute;
              top: 6px;
              left: 10px;
              width: 14px;
              height: 16px;
              border-radius: 0;
              content: '';
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              background-color: transparent;
            }

            &.a-convenience-store:before {
              background-image: url('../images/locator/convenience-store.svg');
            }

            &.a-car-wash:before {
              background-image: url('../images/locator/car-wash.svg');
            }

            &.a-18-wheel-access:before {
              background-image: url('../images/locator/18-wheel-access.svg');
            }

            &.a-prepared-food:before {
              background-image: url('../images/locator/food.svg');
            }

            &.a-fast-stop-cards-only:before {
              background-image: url('../images/locator/fast-stop-card.svg');
            }

            &.a-atm:before {
              background-image: url('../images/locator/atm.svg');
            }

            &.a-scale:before {
              background-image: url('../images/locator/scale.svg');
            }

            &.a-restaurant:before {
              background-image: url('../images/locator/restaurant.svg');
            }

            &.a-credit-card-only:before {
              background-image: url('../images/locator/card.svg');
            }

            &.a-24-hours:before {
              background-image: url('../images/locator/24-hour.svg');
            }

            &.a-auto-service:before {
              background-image: url('../images/locator/auto-service.svg');
            }

            &.a-butcher:before {
              background-image: url('../images/locator/butcher.svg');
            }

            &.a-24-hour-pumps:before {
              background-image: url('../images/locator/24-hour-gas.svg');
            }

            &.a-car:before {
              background-image: url('../images/locator/car.svg');
            }

            &.a-car-care:before {
              background-image: url('../images/locator/car-care.svg');
            }

            &.a-def:before {
              background-image: url('../images/locator/def.svg');
            }

            &.a-farm-store:before {
              background-image: url('../images/locator/farm-store.svg');
            }

            &.a-food:before {
              background-image: url('../images/locator/food.svg');
            }

            &.a-gaming:before {
              background-image: url('../images/locator/gaming.svg');
            }

            &.a-pay-at-the-pump-only:before {
              background-image: url('../images/locator/pay-at-pump.svg');
            }

            &.a-kerosene:before {
              background-image: url('../images/locator/kerosene.svg');
            }

            &.a-no-ethanol-unleaded-gas:before {
              background-image: url('../images/locator/no-ethanol.svg');
            }

            &.a-liquid-propane:before {
              background-image: url('../images/locator/liquid-propane.svg');
            }

            &.a-gasoline:before {
              background-image: url('../images/locator/gasoline.svg');
            }

            &.a-diesel:before {
              background-image: url('../images/locator/diesel.svg');
            }

            &.a-def:before {
              background-image: url('../images/locator/def.svg');
            }

            &.a-e85:before {
              background-image: url('../images/locator/e85.svg');
            }

            &.a-liquid-propane:before {
              background-image: url('../images/locator/liquid-propane.svg');
            }

            &.a-propane-autogas:before {
              background-image: url('../images/locator/propane-autogas.svg');
            }
          }
        }
      }

      //Fuel Prices
      .fuel-prices {
        ul {
          padding-bottom: 15px;

          li {
            padding: 7px 13px;
            margin-bottom: 15px;
          }
        }

        .fuel-type,
        .fuel-price {
          display: block;
          font-size: 14px;
          line-height: 18px;

          &::before {
            content: none;
          }
        }

        .fuel-type {
          color: $monza;
          font-weight: 700;
        }
      }

      .buttons {
        position: relative;
        left: -31px;
        padding: 0;
        width: calc(100% + 62px);
        text-align: center;
        overflow: hidden;
        border: 0;
      }

      .directions,
      .details,
      .share {
        margin: 0;
        @include fontsize(14 24 100);
      }

      .directions {
        // float: left;
        // width: calc(50% - 6px)
        position: relative;
        bottom: -1px;
        // left: -30px;
        margin: -1px 0 0;
        padding: 21px 0;
        // width: calc(100% + 60px);
        @include franklin-cond-med;
        @include fontsize(18 18 100);
        text-transform: uppercase;
        width: 100%;
      }

      .details {
        float: left;
        // width: calc(50% - 1px);
        padding: 17px 0;
        width: 50%;
        @include fontsize(16 16 100);
        color: $codgray;
        // background: $silverchalice;
        background: $silver;
        border-right: 1px solid white;
        border-bottom-left-radius: 10px;

        &:visited {
          color: $codgray;
          // background: $silverchalice;
          background: $silver;
        }

        &:hover,
        &:focus {
          color: white;
          background: $codgray;
        }
      }

      .share {
        // margin: 0 auto;
        float: right;
        padding: 17px 0;
        width: 50%;
        @include fontsize(16 16 100);
        color: $codgray;
        // background: $silverchalice;
        background: $silver;
        border-bottom-right-radius: 10px;

        span {
          display: inline-block;
          margin-bottom: -2px;
          width: 16px;
          height: 15px;
          background: url('../images/ICN-Share.svg') center center / 16px 15px no-repeat;
        }

        &:visited {
          color: $codgray;
          // background: $silverchalice;
          background: $silver;
        }

        &:hover,
        &:focus {
          color: white;
          background: $codgray;
        }
      }
    }
  }

  // Route a Trip
  .store-locator.route-a-trip {
    input[type='text'] {
      // width: 100%;
    }

    .zip {
      width: 100%;
      @include large-up {
        display: flex;
      }
    }

    .location {
      width: 100%;
      @include large-up {
        padding-right: 10px;
        width: 50%;
      }

      input[type='text'] {
        @include large-up {
          width: calc(100% - 60px);
        }
      }
    }

    .destination {
      width: 100%;
      @include large-up {
        // padding-right: 10px;
        width: 50%;
      }
    }

    .swap {
      // .submit {
      display: inline-block;
      margin: 10px auto;
      padding: 12px 10px;
      width: 50px;
      height: 44px;
      @include franklin-book;
      text-transform: uppercase;
      text-align: center;
      white-space: nowrap;
      color: $codgray;
      // width: 50px;
      // height: 50px;

      border: 1px solid $silver;
      border-radius: 10px;
      @include large-up {
        // width: 177px;
        margin: 0;
      }

      span {
        display: inline-block;
        position: relative;
        // top: 4px;
        width: 19px;
        height: 16px;
        background: url('../images/ICN-Swap.svg') center top / 19px 32px no-repeat;
        //   display: inline-block;
        //   position: relative;
        //   top: 3px;
        //   width: 14px;
        //   height: 14px;
        //   background: url('../images/ICN-Filter.svg') center top / 14px 28px no-repeat;
        //   // display: none;
        //   @include large-up {
        //     top: 2px;
        //     margin-right: 5px;
        //   }
      }

      // span + span {
      //   display: none;
      //   top: auto;
      //   width: auto;
      //   height: auto;
      //   background: none;
      //   @include large-up {
      //     display: inline-block;
      //   }
      // }

      &:hover,
      &:focus {
        span {
          background-position: center bottom;
        }
      }
      // }
    }

    .submit {
      // width: 100%;
    }

    // .open-modal {
    //   margin: 0 0 10px 0;
    // }

    // [class*='view'] {
    //   top: 287px;
    //   right: 25px;
    // }
    .waypoints {
      margin-top: 10px;

      .added-to-route {
        display: block;
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }

        .close {
          display: inline-block;
          position: relative;
          bottom: -3px;
          width: 16px;
          height: 16px;
          background: url('../images/ICN-Close-Red.svg') center center / contain no-repeat;
          border: 0;

          &:hover,
          &:focus {
            background-image: url('../images/ICN-Close.svg');
          }
        }

        span {
          display: inline-block;
          padding-left: 5px;
          width: calc(100% - 16px);

          br {
            // display: inline-block;
            content: ', ';
            margin-right: 6px;
          }
        }
      }
    }

    .tbt {
      margin-top: 10px;
      margin-bottom: 0;
    }

    .store-locator-results {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      // .result {
      //   padding-top: 45px;
      // }
    }

    .store-locator-search {
      position: relative;
      top: auto;
      left: auto;
      // text-align: center;

      &:after {
        // display: none;
        top: auto;
        bottom: -30px;
        left: 0;
      }

      .ui-autocomplete div {
        cursor: pointer;
        padding: 5px 10px;
        @include franklin-demi;
        color: $monza;
        border-bottom: 1px solid rgba($codgray, 0.15);

        &:hover,
        &:focus {
          color: $codgray;
          border-color: rgba($codgray, 0.15);
        }
      }

      .ui-autocomplete li:last-child div {
        border-bottom: 0;
      }
    }

    .view-type {
      // display: block;
      float: none;
    }

    .results-list {
      position: relative;
      @include large-up {
        // margin-top: 216px;
        padding-top: 0;
        // height: calc(100% - 216px);
        flex: 1;
        display: flex;
        overflow: auto;

        // &:before {
        //   position: absolute;
        //   top: 0;
        //   height: 30px;
        //   width: 100%;
        //   background: linear-gradient(to top, rgba(white, 1) 0%, rgba(white, 0) 100%);
        //   content: '';
        // }

        .results-list-inner {
          min-height: min-content;
          display: flex;
          flex-wrap: wrap;
          position: relative;
          // padding-top: 30px;

          // &:before {
          //   position: absolute;
          //   top: 0;
          //   height: 30px;
          //   width: 100%;
          //   background: linear-gradient(to top, rgba(white, 1) 0%, rgba(white, 0) 100%);
          //   content: '';
          // }
        }

        .result {
          width: 100%;
        }
      }
    }
  }
}

.store-detail {
  padding-top: 0;
  background: white;

  header.primary,
  footer.primary,
  .banner-wrapper,
  .page-title,
  .skipcontent {
    display: none;
  }

  .content {
    padding: 0;
  }

  .wrap-content {
    margin-top: 0;
  }

  .store-nav {
    display: none;
  }

  .back-to-store {
    // display: block;
    display: none;
    position: relative;
    padding: 18px 0;
    width: 100%;
    @include franklin-book;
    @include fontsize(16 16 40);
    text-transform: uppercase;
    color: $codgray;
    background: $silver;
    border: 0;
    // z-index: 100;
    .inner {
      display: block;
      margin: 0 auto;
      padding-left: 25px;
      padding-right: 25px;
      width: 100%;
      @include xlarge-up {
        padding-left: 120px;
        padding-right: 120px;
        max-width: 1600px;
      }
    }

    span:not(.inner) {
      display: inline-block;
      position: relative;
      top: 3px;
      margin-right: 10px;
      width: 18px;
      height: 16.5px;
      background: url('../images/ICN-Back.svg') center top / 18px 33px no-repeat;
      transition: background-position 0.2s ease-in-out;
    }

    &:visited {
      color: $codgray;
    }

    &:focus,
    &:hover {
      color: $monza;
      text-decoration: none;

      span:not(.inner) {
        background-position: center bottom;
        transition: background-position 0.2s ease-in-out;
      }
    }
  }

  .name {
    margin: 0 0 50px 0;
    padding: 20px 0;
    @include franklin-demi;
    @include fontsize(30 30);
    border-bottom: 1px solid $silver;

    span {
      display: block;
      @include franklin-book;
      @include fontsize(24 30);
    }
  }

  .contact-info {
    margin: 0 0 40px 0;

    li {
      padding: 0;

      &:before {
        display: none;
      }
    }

    .btn-directions {
      display: inline-block;
      margin: 10px 0 0;
      padding: 10px 20px;
      @include franklin-cond-med;
      @include fontsize(18 24 100);
      text-transform: uppercase;
      text-decoration: none;
      text-align: center;
      border: 0;
      // border-bottom: 0;
      box-shadow: none;
      text-shadow: none;
      border-radius: 0;
      color: white;
      background: $monza;
      transition: color 0.2s ease-in-out, background 0.2s ease-in-out;

      &:visited,
      &:active {
        color: white;
        background: $monza;
      }

      &:hover,
      &:focus {
        color: white;
        background: $codgray;
      }
    }
  }

  .three-column {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    @include medium-up {
      margin-left: -20px;
      width: calc(100% + 40px);
    }

    .col {
      margin: 0 0 40px 0;
      width: 100%;
      border: 1px solid $silver;
      border-radius: 10px;
      @include medium-up {
        // padding:
        margin-left: 20px;
        margin-right: 20px;
        width: calc((100% - 80px) / 2);
      }
      @include large-up {
        width: calc((100% - 120px) / 3);
      }
    }

    h3 {
      margin: 0;
      padding: 20px 35px;
      border-bottom: 1px solid $silver;
    }

    ul {
      margin: 0;
      padding: 20px 35px;

      li {
        padding: 0;

        &:before {
          display: none;
        }
      }

      &.services-list li {
        position: relative;
        padding-left: 35px;
        padding-bottom: 4px;

        &:before {
          display: block;
          position: absolute;
          // top: 3px;
          top: 0;
          left: 0;
          width: 25px;
          height: 30px;
          content: '';
          border-radius: 0;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          background-color: transparent;
        }
      }

      &.services-list.amenities li.a-convenience-store:before {
        background-image: url('../images/locator/convenience-store.svg');
      }

      &.services-list.amenities li.a-car-wash:before {
        background-image: url('../images/locator/car-wash.svg');
      }

      &.services-list.amenities li.a-18-wheel-access:before {
        background-image: url('../images/locator/18-wheel-access.svg');
      }

      &.services-list.amenities li.a-prepared-food:before {
        background-image: url('../images/locator/food.svg');
      }

      &.services-list.amenities li.a-fast-stop-cards-only:before {
        background-image: url('../images/locator/fast-stop-card.svg');
      }

      &.services-list.amenities li.a-atm:before {
        background-image: url('../images/locator/atm.svg');
      }

      &.services-list.amenities li.a-scale:before {
        background-image: url('../images/locator/scale.svg');
      }

      &.services-list.amenities li.a-restaurant:before {
        background-image: url('../images/locator/restaurant.svg');
      }

      &.services-list.amenities li.a-credit-card-only:before {
        background-image: url('../images/locator/card.svg');
      }

      &.services-list.amenities li.a-24-hours:before {
        background-image: url('../images/locator/24-hour.svg');
      }

      &.services-list.amenities li.a-auto-service:before {
        background-image: url('../images/locator/auto-service.svg');
      }

      &.services-list.amenities li.a-butcher:before {
        background-image: url('../images/locator/butcher.svg');
      }

      &.services-list.amenities li.a-24-hour-pumps:before {
        background-image: url('../images/locator/24-hour-gas.svg');
      }

      &.services-list.amenities li.a-car:before {
        background-image: url('../images/locator/car.svg');
      }

      &.services-list.amenities li.a-car-care:before {
        background-image: url('../images/locator/car-care.svg');
      }

      &.services-list.amenities li.a-def:before {
        background-image: url('../images/locator/def.svg');
      }

      &.services-list.amenities li.a-farm-store:before {
        background-image: url('../images/locator/farm-store.svg');
      }

      &.services-list.amenities li.a-food:before {
        background-image: url('../images/locator/food.svg');
      }

      &.services-list.amenities li.a-gaming:before {
        background-image: url('../images/locator/gaming.svg');
      }

      &.services-list.amenities li.a-pay-at-the-pump-only:before {
        background-image: url('../images/locator/pay-at-pump.svg');
      }

      &.services-list.amenities li.a-kerosene:before {
        background-image: url('../images/locator/kerosene.svg');
      }

      &.services-list.amenities li.a-no-ethanol-unleaded-gas:before {
        background-image: url('../images/locator/no-ethanol.svg');
      }

      &.services-list.amenities li.a-liquid-propane:before {
        background-image: url('../images/locator/liquid-propane.svg');
      }

      &.services-list.fuels li.a-gasoline:before {
        background-image: url('../images/locator/gasoline.svg');
      }

      &.services-list.fuels li.a-diesel:before {
        background-image: url('../images/locator/diesel.svg');
      }

      &.services-list.fuels li.a-def:before {
        background-image: url('../images/locator/def.svg');
      }

      &.services-list.fuels li.a-e85:before {
        background-image: url('../images/locator/e85.svg');
      }

      &.services-list.fuels li.a-liquid-propane:before {
        background-image: url('../images/locator/liquid-propane.svg');
      }

      &.services-list.fuels li.a-propane-autogas:before {
        background-image: url('../images/locator/propane-autogas.svg');
      }

      &.services-list.payments li {
        position: relative;
        padding-left: 45px;
        padding-bottom: 4px;

        &:before {
          display: block;
          position: absolute;
          top: 3px;
          left: 0;
          width: 40px;
          height: 25px;
          content: '';
          border-radius: 0;
        }
      }

      &.services-list.payments li:before {
        background: url('../images/sprite-payments.png') top center / 40px 313px no-repeat;
      }

      &.services-list.payments li.a-american-express:before {
        background-position: center 0;
      }

      &.services-list.payments li.a-discover:before {
        background-position: center -41px;
      }

      &.services-list.payments li.a-fast-stop-cards:before {
        background-position: center -82px;
      }

      &.services-list.payments li.a-gift-card:before {
        background-position: center -124px;
      }

      &.services-list.payments li.a-mastercard:before {
        background-position: center -165px;
      }

      &.services-list.payments li.a-visa:before {
        background-position: center -206px;
      }

      &.services-list.payments li.a-voyager:before {
        background-position: center -247px;
      }

      &.services-list.payments li.a-wright-express:before {
        background-position: center -288px;
      }
    }
  }
}
// sidebar
.sidebar {
  padding-top: 20px;
  width: 100%;
  @include xlarge-up {
    padding-top: 46px;
    padding-right: 80px;
    // width: 360px;
    width: 32%;
    // background: white;
  }
}

.callout {
  position: relative;
  margin: 0 0 20px 0;
  padding: 0 20px 75px 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 0px rgba(black, 0.16);
  overflow: hidden;
  @include medium-up {
    margin: 0 0 40px 0;
    padding: 0 40px 25px 40px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  > p {
    padding: 0;
  }

  img {
    position: relative;
    left: -20px;
    // margin-bottom: 40px;
    width: calc(100% + 40px);
    max-width: calc(100% + 40px);
    @include medium-up {
      left: -40px;
      width: calc(100% + 80px);
      max-width: calc(100% + 80px);
    }
  }

  .btn-primary {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 12px 0;
    width: 100%;
    height: 50px;
    @include franklin-demi;
    @include fontsize(16 25 50);
    color: white;
    text-decoration: none;
    text-transform: none;
    text-align: center;
    background: $monza;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: background 0.2s ease-in-out;

    &:after {
      // position: absolute;
      // left: 0;
      // right: 0;
      // margin: 0 auto;
      // display: inline-block;
      // font-style: normal;
      // font-variant: normal;
      // text-rendering: auto;
      // -webkit-font-smoothing: antialiased;
      // font-family: 'Font Awesome 5 Free';
      // font-weight: 900;
      // content: '\f061';
      // font-size: 11px;
    }

    &:visited {
      color: white;
    }

    &:hover,
    &:focus {
      color: white;
      text-decoration: none;
      background: $tundora;
      transition: background 0.2s ease-in-out;
    }
  }
}
// forms
.content-form,
.form-container,
.dnnForm {
  &.overflow {
    overflow: visible;
  }

  label,
  .label {
    display: block;
    @include franklin-demi;
    @include fontsize(18 30);
    text-align: left;
    color: $codgray;
  }

  input[type='text'],
  input[type='password'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='month'],
  input[type='week'],
  input[type='email'],
  input[type='number'],
  input[type='search'],
  input[type='tel'],
  input[type='time'],
  input[type='url'],
  input[type='file'],
  textarea,
  select {
    padding: 0 10px;
    height: 55px;
    width: 100%;
    @include fontsize(18 55);
    border: 1px solid $silver;
    border-radius: 10px;
  }

  select {
    background: url('../images/ICN-caret.svg') top right / 55px 110px no-repeat;

    &.open {
      background-position: bottom right;
    }
  }

  textarea {
    min-height: 110px;
    @include fontsize(18 24);
  }

  .dnnLoginActions .dnnActions {
    li {
      padding: 0;

      &:before {
        display: none;
      }
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    border: 1px solid $silver;

    + label {
      display: inline-block;
      top: -5px;
    }

    &:checked {
      border-color: $monza;
      background: $monza;
      appearance: none;

      &:before {
        position: absolute;
        top: 5px;
        left: 3px;
        width: 12px;
        height: 9px;
        background: url('../images/ICN-Checkmark.svg') center center / contain no-repeat;
        content: '';
        // text-decoration: inherit;
        // color: white;
        // font-family: FontAwesome;
        // font-size: 15px;
        // font-weight: normal;
        // font-style: normal;
      }
    }
  }

  input[type='radio']:checked:before {
    display: none;
  }

  // .DnnModule.DnnModule-DNN_UserDefinedTable.DnnModule-441.DnnModule-Admin .dnnForm {
  //   padding-right: $gutter / 2;
  //   padding-left: $gutter / 2;
  // }
}
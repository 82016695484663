.accordions.active {
  margin-bottom: 20px;

  // zero out h2 if accordions are active (JS is active)
  h2.accordion-title {
    position: relative;
    margin: 0;
    padding: 0;
  }

  // style just the button so that h2's maintain default styling
  h2.accordion-title button {
    position: relative;
    width: 100%;
    margin: 0 0 10px 0;
    padding: 20px 82px 10px 0;
    text-align: left;
    color: black;
    border: 0;
    border-bottom: 2px solid gray;
    background: transparent;
    font-weight: 700;
    @include fontsize(15 18);

    span {
      position: absolute;
      right: 0;
      bottom: -2px;
      width: 80px;
      height: calc(100% + 2px);
      border-bottom: 2px solid gray;

      &:after {
        position: absolute;
        top: 50%;
        left: 32px;
        content: '\f067';
        transform: translateY(-8px);
        text-align: center;
        text-decoration: inherit;
        color: black;
        font-family: FontAwesome;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
      }
    }

    &:hover {
      cursor: pointer;
    }

    &:hover,
    &:focus {
      color: blue;
      border: 0;
      border-bottom: 2px solid gray;
      outline: 0;
      background: transparent;

      span {
        border-bottom: 2px solid gray;
      }
    }
  }

  // style just the button when its focused but the accordion is not open
  h2.accordion-title.is-active button:focus {
  }

  // if the accordion is open style the button
  h2.accordion-title.is-active button {
    color: blue;
    border: 0;
    border-bottom: 2px solid gray;
    outline: 0;
    background: transparent;

    span {
      border-bottom: 2px solid gray;

      &:after {
        content: '\f068';
      }
    }
  }
  // if the accordion is open and the button has focus
  h2.accordion-title.is-active button:focus {
    color: blue;
    border: 0;
    border-bottom: 2px solid gray;
    outline: 0;
    background: transparent;

    span {
      border-bottom: 2px solid gray;

      &:after {
        content: '\f068';
      }
    }
  }

  // hide the content by default (if JS)
  .accordion-content[aria-hidden='true'] {
    display: none;
  }

  // show the content when aria-hidden is set to false by the JS
  .accordion-content[aria-hidden='false'] {
    display: block;
  }

  // style the accordion content
  .accordion-content {
    padding: 10px;
  }
}
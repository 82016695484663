// accordions
.accordions.active {
  margin: 0 0 25px 0;

  h2.accordion-title {
    button {
      padding: 12px 80px 12px 0;
      @include franklin-demi;
      @include fontsize(18 30);
      color: $codgray;
      // border-bottom: 2px solid rgba($codgray, 0.3);
      border: 0;

      &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc(100% - 80px);
        height: 2px;
        background: rgba($codgray, 0.3);
        content: '';
      }

      &:after {
        position: absolute;
        top: 0;
        right: 0;
        width: 75px;
        height: 100%;
        background: url('../images/ICN-Plus.svg') center center / 15px 15px no-repeat;
        // border-left: 5px solid white;
        border-bottom: 2px solid $codgray;
        content: '';
      }
    }

    button:hover,
    button:focus {
      color: $monza;
      border: 0;

      &:before {
        background: $monza;
      }

      &:after {
        background: url('../images/ICN-Plus-Red.svg') center center / 15px 15px no-repeat;
        border-color: $monza;
      }
    }

    &.is-active {
      button {
        color: $codgray;
        border: 0;

        &:hover,
        &:focus {
          color: $codgray;
          border: 0;
        }

        &:before {
          background: $codgray;
        }

        &:after {
          background: url('../images/ICN-Minus-Black.svg') center center / 15px 3px no-repeat;
          border-color: $codgray;
        }
      }
    }
  }

  .accordion-content {
    padding: 0;
  }
}
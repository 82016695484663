.cta {
  display: flex;
  position: relative;
  align-items: center;
  margin: 50px 0;
  padding: 50px 65px 50px 0;
  @include franklin-demi;
  @include fontsize(24 30);
  color: $codgray;
  text-decoration: none;
  border-top: 2px solid $silver;
  border-bottom: 2px solid $silver;

  &:hover {
    color: $codgray;

    &:after {
      right: 0;
      @include transition(right 0.2s ease-in-out);
    }
  }

  &:after {
    display: block;
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
    width: 25px;
    height: 23px;
    background: url('../images/ICN-Arrow-Red.svg') center center / 25px 23px no-repeat;
    content: '';
    @include transition(right 0.2s ease-in-out);
  }

  .icon {
    width: 80px;
    // height: 80px;

    p {
      margin: 0;
      padding: 0;
      line-height: 1;
    }

    img {
      margin: 0;
      padding: 0;
    }
  }

  .img {
    width: 140px;

    p {
      margin: 0;
      padding: 0;
      line-height: 1;
    }

    img {
      margin: 0;
      padding: 0;
    }
  }

  .content {
    padding: 0 0 0 20px;
  }

  &.cta-icon .content {
    width: calc(100% - 80px);
  }

  p {
    @include fontsize(24 30);

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
.subpage {
  .banner-wrapper {
    position: relative;
    // height: 100px;
    height: 120px;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url('../images/bg-banner-sp.jpg');
    background-color: $tundora;
    @include medium-up {
      background-image: url('../images/bg-banner-sp@2x.jpg');
    }
    @include xlarge-up {
      height: 200px;
      flex: 0 0 100%;
    }

    // &:before {
    //   position: absolute;
    //   top: 20%;
    //   left: 0;
    //   // transform: translateZ(-50%);
    //   width: 100%;
    //   height: 60%;
    //   background: url('../images/ICN-Banner-Faint.png') left center / contain no-repeat;
    //   content: '';
    //   z-index: 2;
    //   @include xlarge-up {
    //     height: 108px;
    //     top: 50%;
    //     transform: translateY(-50%);
    //   }
    // }

    // &:after {
    //   position: absolute;
    //   bottom: -6px;
    //   left: 0;
    //   height: 5px;
    //   width: 100%;
    //   background: $tundora;
    //   content: '';
    // }
  }

  .banner {
    display: none;
    @include xlarge-up {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      // width: 27%;
      // width: 360px;
      width: 32%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      z-index: 3;
      border-left: 1px solid white;
    }
  }
}
img {
  display: block;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  @include medium-up {
    max-width: 100%;
  }

  &[class*='left'] {
    float: left;
    max-width: 40%;
    margin: 0 20px 15px 0;
    @include medium-up {
      margin: 0 50px 20px 0;
    }
  }

  &[class*='right'] {
    float: right;
    max-width: 40%;
    margin: 0 0 15px 20px;
    @include medium-up {
      margin: 0 0 20px 50px;
    }
  }

  &[class*='center'] {
    margin: 0 auto 15px auto;
    @include medium-up {
      margin: 0 auto 20px auto;
    }
  }
}

figure {
  display: block;
  margin: 0 0 25px 0;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  @include medium-up {
    max-width: 100%;
  }

  img {
    display: block;
    margin: 0 auto;
  }

  img[class*='align'] {
    margin: 0 auto;
    float: none;
    @include medium-up {
      margin: 0 auto;
      float: none;
    }
  }

  &[class*='left'] {
    float: left;
    max-width: 40%;
    margin: 0 20px 15px 0;
    @include medium-up {
      margin: 0 50px 20px 0;
    }
  }

  &[class*='right'] {
    float: right;
    max-width: 40%;
    margin: 0 0 15px 20px;
    @include medium-up {
      margin: 0 0 20px 50px;
    }
  }

  &[class*='center'] {
    margin: 0 auto 15px auto;
    @include medium-up {
      margin: 0 auto 20px auto;
    }
  }

  figcaption {
    margin: 0 auto;
    font-style: italic;
    @include franklin-demi;
    @include fontsize(14 20);
    // color: white;
    background: transparent;
    padding: 10px 0 0;
  }
}